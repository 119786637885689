import * as React from "react"
function SvgChevronFilledRight(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M7 20V4l10 8-10 8z" fill="currentColor" />
        </svg>
    )
}
export default SvgChevronFilledRight
