import * as React from "react"
function SvgLoop(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={12} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.138 7.138l-.943-.943L6.391 10l3.804 3.805.943-.943-2.195-2.195h4.39a2.667 2.667 0 110 5.333H12v1.333h1.333a4 4 0 100-8h-4.39l2.195-2.195z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgLoop
