import * as React from "react"
function SvgArchive(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3 5a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V5zM5 8h14v10a1 1 0 01-1 1H6a1 1 0 01-1-1V8zM9 13.5h6"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgArchive
