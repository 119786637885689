import * as React from "react"
function SvgDisconnected(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 12a5 5 0 015-5h2v2H6a3 3 0 100 6h2v2H6a5 5 0 01-5-5zm15-5h2a5 5 0 010 10h-2v-2h2a3 3 0 000-6h-2V7z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgDisconnected
