import * as React from "react"
function SvgChannel(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={2} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 12a4 4 0 00-4-4V6a6 6 0 016 6h-2zm-8 0a4 4 0 004 4v2a6 6 0 01-6-6h2z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12a8 8 0 00-8-8V2c5.523 0 10 4.477 10 10h-2zM4 12a8 8 0 008 8v2C6.477 22 2 17.523 2 12h2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgChannel
