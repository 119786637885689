import * as React from "react"
function SvgUpload(props) {
    return (
        <svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 .586l4.707 4.707-1.414 1.414L10 4.414V13H8V4.414L5.707 6.707 4.293 5.293 9 .586zM0 14v3a2 2 0 002 2h14a2 2 0 002-2v-3h-2v3H2v-3H0z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgUpload
