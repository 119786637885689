import * as React from "react"
function SvgMinor(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={10} fill="#29C279" />
        </svg>
    )
}
export default SvgMinor
