import * as React from "react"
function SvgFlow(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3.388.617A3.502 3.502 0 0122 5.5a3.5 3.5 0 01-6.815 1.127l-.663.094c-.502.072-1 .577-1.1 1.31-.125.895-.404 2.014-1.048 2.974-.244.365-.539.703-.89.995.351.292.646.63.89.995.644.96.923 2.079 1.047 2.974.102.733.599 1.238 1.101 1.31l.663.095A3.501 3.501 0 0122 18.5a3.5 3.5 0 01-6.888.883l-.873-.124c-1.605-.23-2.609-1.64-2.799-3.016-.104-.747-.321-1.529-.727-2.135-.374-.558-.915-.985-1.78-1.108h-.08a3.502 3.502 0 01-6.856-1 3.5 3.5 0 016.855-1h.082c.864-.123 1.405-.55 1.779-1.108.406-.606.623-1.388.727-2.135.19-1.375 1.194-2.786 2.8-3.016l.872-.124zM5.497 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM18.5 17a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgFlow
