import { Entity } from "../common/Entity"
import { RefKind } from "../common/RefKind"

export const entityToRefKind = {
    [Entity.AccessRelation]: RefKind.Relation,
    [Entity.Actor]: RefKind.Actor,
    [Entity.Channel]: RefKind.Channel,
    [Entity.Comment]: RefKind.Comment,
    [Entity.Confirmation]: RefKind.Confirmation,
    [Entity.Customer]: RefKind.Customer,
    [Entity.CustomerBilling]: RefKind.CustomerBilling,
    [Entity.Favorite]: RefKind.Favorite,
    [Entity.File]: RefKind.File,
    [Entity.ImageContentBlock]: RefKind.ContentBlock,
    [Entity.Invite]: RefKind.Invite,
    [Entity.IP]: RefKind.Invite,
    [Entity.Issue]: RefKind.Issue,
    [Entity.LinkContentBlock]: RefKind.ContentBlock,
    [Entity.Login]: RefKind.Login,
    [Entity.Membership]: RefKind.Membership,
    [Entity.Mutation]: RefKind.Mutation,
    [Entity.NoteContentBlock]: RefKind.ContentBlock,
    [Entity.PasswordResetToken]: RefKind.PasswordResetToken,
    [Entity.Path]: RefKind.ProcessItem,
    [Entity.Process]: RefKind.Process,
    [Entity.PublicLink]: RefKind.PublicLink,
    [Entity.Reference]: RefKind.ProcessItem,
    [Entity.Run]: RefKind.Run,
    [Entity.Sequence]: RefKind.ProcessItem,
    [Entity.Session]: RefKind.Session,
    [Entity.Split]: RefKind.ProcessItem,
    [Entity.SSOConfiguration]: RefKind.SSOConfiguration,
    [Entity.SSOUser]: RefKind.SSOUser,
    [Entity.Step]: RefKind.ProcessItem,
    [Entity.Tag]: RefKind.Tag,
    [Entity.Task]: RefKind.Task,
    [Entity.User]: RefKind.User,
    [Entity.Variant]: RefKind.Variant,
    [Entity.Version]: RefKind.Version,
}
