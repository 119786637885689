import * as React from "react"
function SvgDuplicate(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.667 3A2.333 2.333 0 0121 5.333v9.334A2.333 2.333 0 0118.666 17H17v1.667A2.333 2.333 0 0114.667 21H5.333A2.333 2.333 0 013 18.667V9.333A2.333 2.333 0 015.333 7H7V5.333A2.333 2.333 0 019.333 3h9.334zM6.999 9H5.333A.333.333 0 005 9.333v9.334c0 .184.15.333.333.333h9.334c.184 0 .333-.15.333-.333V17H9.333a2.333 2.333 0 01-2.334-2.333V9zm12-3.667A.333.333 0 0018.668 5H9.333a.333.333 0 00-.334.333v9.334c0 .184.15.333.334.333h9.333c.184 0 .334-.15.334-.333V5.333z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgDuplicate
