import * as React from "react"
function SvgQuestion(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 8c0-2 1.333-4 4-4s4 2 4 3.5c0 4-4.5 2.5-4.5 8"
                stroke="currentColor"
                strokeWidth={2}
            />
            <circle cx={11.5} cy={19.5} r={1.5} fill="currentColor" />
        </svg>
    )
}
export default SvgQuestion
