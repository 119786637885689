import { PortableText } from "@portabletext/react"
import { serializers } from "./Serializers"
import { css } from "@shiftx/styles"

export const BodyText = ({ content, ...props }) => {
    return (
        <div
            css={css`
                img {
                    width: 100%;
                    margin: 24px 0;
                }

                figure {
                    margin: 0;
                }

                ul {
                    list-style: none;
                    margin: 24px 0;
                    padding: 0;
                }

                h2:not(h2 a).a,
                h3:not(h3 a).a {
                    color: var(--brand-base);
                }

                a {
                    color: var(--brand-base);
                }

                h1 a,
                h2 a,
                h3 a {
                    color: var(--fg-high);
                }

                li::before {
                    content: "•";
                    color: var(--brand-base);
                    display: inline-block;
                    width: 1.5rem;
                    font-size: 20px;
                }

                li {
                    margin-bottom: 4px;
                    font-size: 16px;
                }

                p:first-child {
                    margin-top: 0;
                }

                img {
                    width: 100%;
                }

                figure {
                    margin: 0;
                }

                h1,
                h2,
                h3 {
                    margin-top: 24px;
                }

                h1 {
                    font-size: 48px;
                    line-height: 56px;
                    color: var(--fg-high);
                    font-weight: bold;
                    margin-bottom: 0;
                }

                h2 {
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 48px;
                    color: var(--fg-high);
                    margin-bottom: 0;
                }

                h3 {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 40px;
                    color: var(--fg-high);
                    margin-bottom: 0;
                }

                blockquote {
                    font-weight: 500;
                    margin: 60px 0px;
                    text-align: left;
                    border-left: 4px solid #eee;
                    padding-left: 24px;
                }
            `}
            {...props}
        >
            <PortableText value={content} components={serializers} />
        </div>
    )
}
