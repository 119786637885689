import * as React from "react"
function SvgInfo(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={12} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.167 8.333a1.167 1.167 0 100-2.333 1.167 1.167 0 000 2.333zm-1.5 8.334V18h3v-1.333H13V10h-2.333v1.333h.666v5.334h-.666z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgInfo
