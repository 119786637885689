export enum Entity {
    AccessRelation = "AccessRelation",
    Actor = "Actor",
    Channel = "Channel",
    Comment = "Comment",
    Confirmation = "Confirmation",
    Customer = "Customer",
    CustomerBilling = "CustomerBilling",
    Favorite = "Favorite",
    File = "File",
    ImageContentBlock = "ImageContentBlock",
    Invite = "Invite",
    IP = "IP",
    Issue = "Issue",
    LinkContentBlock = "LinkContentBlock",
    Login = "Login",
    Membership = "Membership",
    Mutation = "Mutation",
    NoteContentBlock = "NoteContentBlock",
    PasswordResetToken = "PasswordResetToken",
    Path = "Path",
    Process = "Process",
    PublicLink = "PublicLink",
    Reference = "Reference",
    Run = "Run",
    Sequence = "Sequence",
    Session = "Session",
    Step = "Step",
    Split = "Split",
    SSOConfiguration = "SSOConfiguration",
    SSOUser = "SSOUser",
    Tag = "Tag",
    Task = "Task",
    User = "User",
    Variant = "Variant",
    Version = "Version",
}
