import * as React from "react"
function SvgPlay(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.496 19.145A1 1 0 016 18.277V5.723a1 1 0 011.496-.868l10.985 6.277a1 1 0 010 1.736L7.496 19.145z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgPlay
