import * as React from "react"
function SvgPlayOutline(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 22l16-10L5 2v20zm2-3.737L17.394 12 7 5.737v12.526z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgPlayOutline
