import * as React from "react"
function SvgPencil(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.943 4.943l2.114 2.114c.52.52.52 1.365 0 1.886l-9.724 9.724L4 20l1.333-5.333 9.724-9.724c.52-.52 1.365-.52 1.886 0z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinejoin="round"
            />
            <path
                d="M13.333 6.667l4 4M6.667 13.333l4 4"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgPencil
