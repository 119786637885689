import * as React from "react"
function SvgFlag(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9 21l-2-8.5m0 0L5 4h12l2 8.5H7z"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgFlag
