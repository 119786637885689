import styledCss from "@emotion/styled"
export type { EmotionCache } from "@emotion/cache"
export {
    Global,
    ThemeProvider,
    css,
    jsx,
    keyframes,
    useTheme,
} from "@emotion/react"
export { SetThemedVariables } from "./SetThemedVariables"
export { getColor, getThemeProp } from "./functions"
export { breakpoints } from "./shared"
export { darkTheme } from "./themes/dark"
export { lightTheme } from "./themes/light"
export { TextStyle } from "./types"

export const styled = styledCss

export { cssVar } from "./cssVar"
export { scrollBarIsObtrusive } from "./scrollBarIsObtrusive"

import defaultCreateCache from "@emotion/cache"
export const createCache = defaultCreateCache
