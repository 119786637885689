import { css } from "@shiftx/styles"
import { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { generateSync } from "vanilla-captcha/dom"
import { ButtonType, LoadingButton } from "../../combonentLibary"
import { Error } from "../../pages/demo"
import { lessThan } from "../utils/breakpoints"
import { bookDemo } from "../utils/apiRequests"
import { Input } from "./Input"
import { LinkButtonAction } from "./LinkButton"
import { Section } from "./Section"
import { Title3 } from "./Text"

const SimpleCaptcha = ({ setValue, captchaIncrement }) => {
    const [captcha, setCaptcha] = useState<string>()
    const inputRef = useRef()
    useEffect(() => {
        const { answer, captcha } = generateSync(5, {
            fontColor: "#4040f0",
            lineColor: "#4040f0",
            width: 300,
            height: 75,
            lineAmount: 30,
        })
        setCaptcha(captcha)
        setValue("captcha.answer", answer.toLowerCase())
        setValue("captcha.input", "")
        inputRef.current.value = ""
    }, [captchaIncrement])
    return (
        <div>
            <img
                css={css`
                    width: 100%;
                    border: 1px solid var(--fg-300);
                    border-radius: 4px;
                    margin-top: 8px;
                    box-sizing: border-box;
                `}
                src={captcha}
            ></img>
            <Input
                ref={inputRef}
                css={css`
                    /* width: 50%; */
                `}
                onChange={e => {
                    const value = e.target.value.toLowerCase()
                    setValue("captcha.input", value)
                }}
                placeholder="Are you human?"
            />
        </div>
    )
}

export const SeeForYourself = ({
    button = { label: "Get started", link: "/pricing" },
}: {
    button?: { label: string; link: string }
    useCases?: any
    compare?: any
    isCampaign?: boolean
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            email: "",
            captcha_required: false,
            captcha: {
                required: false,
                answer: "",
                input: "",
            },
        },
    })

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [needsCaptcha, setNeedsCaptcha] = useState(false)
    const [captchaIncrement, setCaptchaIncrement] = useState(0)
    const captcha = watch("captcha_required")

    useEffect(() => {
        if (needsCaptcha) {
            // setValue("captcha_required", true)
            setValue("captcha.required", true)
            // register("captcha_required")
        }
    }, [needsCaptcha])
    console.log({ captcha, errors })
    const onSubmit = async ({ email, company, captcha, ...rest }) => {
        if (captcha.required && captcha.answer !== captcha.input) {
            setCaptchaIncrement(curr => (curr += 1))
        } else {
            const captchaValue =
                captcha.required && captcha.answer === captcha.input
                    ? "fm"
                    : undefined
            const analytics_id =
                JSON.parse(localStorage.getItem("ajs_user_id")) ||
                JSON.parse(localStorage.getItem("ajs_anonymous_id"))

            const { data, errors } = await bookDemo({
                company,
                email,
                captcha: captchaValue,
                analytics_id,
            }).catch(err => {
                setHasError(true)
            })

            if (data?.demoRequest?.ok) {
                setIsSubmitted(true)
                setHasError(false)
            } else if (
                errors.length &&
                errors[0]?.message === "captcha_required"
            ) {
                setNeedsCaptcha(true)
            } else {
                setHasError(true)
            }
        }
    }

    return (
        <div css={css``}>
            <Section
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px;
                    ${lessThan(0)`
                    display: grid;
                        gap: 24px;
                    `}
                `}
            >
                <div
                    css={css`
                        flex: 1;
                        background: linear-gradient(
                            0deg,
                            rgba(38, 38, 144, 0) 0%,
                            rgba(64, 64, 240, 0.02) 100%
                        );
                        min-width: 300px;
                        border: 1px rgba(38, 38, 144, 0.08) solid;
                        border-radius: 16px;
                        padding: 64px;
                        ${lessThan(1)`
                            padding: 48px;

                        `}
                        ${lessThan(0)`
                            padding: 24px;
                            min-width: auto;
                        `}
                    `}
                >
                    <Title3
                        css={css`
                            font-size: 32px;
                            text-align: left;
                            background: linear-gradient(
                                45deg,
                                #262690 20%,
                                #4040f0 80%
                            );
                            background-size: 100%;
                            background-clip: text;
                            text-fill-color: transparent;
                        `}
                    >
                        Give it a go - it's free
                    </Title3>
                    <p
                        css={css`
                            font-size: 16px;
                            line-height: 28px;
                            margin-top: 8px;
                            margin-bottom: 40px;
                            max-width: 400px;
                        `}
                    >
                        Set up your own single-user, free-forever account, or
                        try ShiftX free for 14 days with the rest of your
                        colleagues.
                    </p>
                    <LinkButtonAction
                        href="/signup"
                        onClick={() => {
                            analytics.track("Get started", {
                                source: "frontpage",
                            })
                        }}
                    >
                        {button.label}
                    </LinkButtonAction>
                </div>
                <div
                    css={css`
                        flex: 1;
                        background: linear-gradient(
                            0deg,
                            rgba(38, 38, 144, 0) 0%,
                            rgba(64, 64, 240, 0.02) 100%
                        );
                        min-width: 300px;
                        border: 1px rgba(38, 38, 144, 0.08) solid;
                        border-radius: 16px;
                        padding: 64px;
                        ${lessThan(1)`
                            padding: 48px;
                        `}
                        ${lessThan(0)`
                            padding: 24px;
                            min-width: auto;
                        `}
                    `}
                >
                    <div>
                        <Title3
                            css={css`
                                font-size: 28px;
                                line-height: 40px;
                                text-align: left;
                                background: linear-gradient(
                                    45deg,
                                    #262690 20%,
                                    #4040f0 80%
                                );
                                background-size: 100%;
                                background-clip: text;
                                text-fill-color: transparent;
                            `}
                        >
                            Show me more
                        </Title3>

                        <p>
                            Register for a short and informal product tour. No
                            strings attached.
                        </p>
                        {!isSubmitted && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Input
                                    {...register("company", {
                                        required: {
                                            value: true,
                                        },
                                    })}
                                    placeholder="Company name"
                                    css={css`
                                        margin-bottom: 8px;
                                    `}
                                />

                                <Input
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Email is required",
                                        },
                                        pattern: {
                                            value: /\S+@\S+.\S+/,
                                            message:
                                                "Entered value does not match email format",
                                        },
                                    })}
                                    placeholder="Company e-mail"
                                />

                                {needsCaptcha && (
                                    <SimpleCaptcha
                                        setValue={setValue}
                                        captchaIncrement={captchaIncrement}
                                    />
                                )}

                                {errors.email && (
                                    <Error>
                                        {errors?.email?.message ||
                                            "Entered value does not match email format"}
                                    </Error>
                                )}
                                {hasError && (
                                    <Error>An unkown error occured</Error>
                                )}

                                <LoadingButton
                                    type="submit"
                                    isLoading={isSubmitting}
                                    variant={ButtonType.Primary}
                                    css={css`
                                        margin-top: 24px;
                                    `}
                                >
                                    Submit
                                </LoadingButton>
                            </form>
                        )}

                        {isSubmitted && (
                            <div
                                css={css`
                                    margin-top: 8px;
                                    font-weight: 400;
                                    font-size: 13px;
                                `}
                            >
                                Your demo request has been received. We will be
                                in touch very soon.
                            </div>
                        )}
                    </div>
                </div>
            </Section>
        </div>
    )
}
