import * as React from "react"
function SvgActorInvisible(props) {
    return (
        <svg
            width={421}
            height={480}
            viewBox="0 0 421 480"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M210.012 200.014c44.192 0 80.007-35.821 80.007-80.007 0-44.186-35.815-80.007-80.007-80.007-44.191 0-80.007 35.82-80.007 80.007 0 44.186 35.816 80.007 80.007 80.007zM40.226 440h339.573c-3.874-64.144-57.137-114.983-122.253-114.983h-95.068C97.37 325.017 44.1 375.858 40.226 440zm289.793-319.993c0 66.276-53.722 120.007-120.007 120.007S90.005 186.283 90.005 120.007C90.005 53.731 143.727 0 210.012 0s120.007 53.731 120.007 120.007zm-167.541 165.01h95.068c89.724 0 162.478 72.754 162.478 162.478 0 18.001-14.532 32.533-32.477 32.505H32.496C14.55 480 0 465.44 0 447.495c0-89.724 72.764-162.478 162.478-162.478z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgActorInvisible
