import { type ProcessRef, type ProcessItemRef } from "@shiftx/types"
import { idFromRef, parseRef } from "@shiftx/utils"

interface ItemLinkProps {
    processRef: ProcessRef
    itemRef: ProcessItemRef
    version?: string | "current"
}

interface CommentLinkProps {
    processRef: string
    commentRef: string
}

interface ItemUrlProps {
    processId: string
    itemId: string
}

export const relativeURLToAbsoluteURL = (relativeLink: string) =>
    `${window.location.origin}${relativeLink}`

export const copyItemLink = ({
    processRef,
    itemRef,
    version = null,
}: ItemLinkProps): void => {
    const { id: itemId } = parseRef(itemRef)
    const { id: processId } = parseRef(processRef)
    const viewOrEditor = version ? `/view/` : `/editor/`
    navigator.clipboard.writeText(
        `${
            window.location.origin
        }/processes/${processId}${viewOrEditor}?selected=${encodeURIComponent(
            `type=item&id=${itemId}`
        )}`
    )
}
export const generateProcessItemURL = ({
    ref,
    processRef,
    versionRef,
    absolute = true,
}) => {
    const id = idFromRef(ref)
    const processId = idFromRef(processRef)
    const viewOrEditor = versionRef ? `view` : `editor`
    const relative = `/processes/${processId}/${viewOrEditor}/?selected=${encodeURIComponent(
        `type=item&id=${id}`
    )}`
    return absolute ? relativeURLToAbsoluteURL(relative) : relative
}

export const copyCommentLink = ({
    processRef,
    commentRef,
}: CommentLinkProps): void => {
    navigator.clipboard.writeText(
        `${window.location.origin}${createCommentLink({
            processRef,
            commentRef,
        })}`
    )
}

export const createCommentLink = ({ processRef, commentRef }) => {
    const { id: processId } = parseRef(processRef)
    const { id: commentId } = parseRef(commentRef)
    return `/processes/${processId}/editor/?selected=${encodeURIComponent(
        `type=comment&id=${commentId}`
    )}`
}

export const createIssueLink = ({ processRef, issueRef }) => {
    const { id: processId } = parseRef(processRef)
    const { id: issueId } = parseRef(issueRef)
    return `/processes/${processId}/editor/?selected=${encodeURIComponent(
        `type=issue&id=${issueId}`
    )}`
}

const itemUrl = ({ processId, itemId }: ItemUrlProps): string => {
    return `${
        window.location.origin
    }/processes/${processId}/editor/?selected=${encodeURIComponent(
        `type=item&id=${itemId}`
    )}`
}
