import * as React from "react"
function SvgStarOutlined(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.939 7.955L11.999 2 9.062 7.955l-6.572.955 4.756 4.635-1.123 6.545L12 17l5.878 3.09-1.123-6.545 4.756-4.635-6.572-.955zm2.274 2.351l-3.602-.523L12 6.519l-1.61 3.264-3.603.523 2.607 2.54-.616 3.588L12 14.74l3.222 1.694-.616-3.587 2.607-2.54z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgStarOutlined
