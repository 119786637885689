import * as React from "react"
function SvgNote(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 7H4V5h16v2zm0 6H4v-2h16v2zM4 19h10v-2H4v2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgNote
