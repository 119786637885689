import { sxHashId } from "./sxHashId"

export const decodeHashId = (
    id: string,
    { returnAsString = true, firstOnly = false } = {}
) => {
    let res = sxHashId.decode(id)
    if (returnAsString) res = res.map(num => String(num))
    if (firstOnly) return res[0] as string
    return res as string[]
}
