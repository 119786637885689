import { isRunningInBrowser } from "@shiftx/utils"

export const isProd = () => {
    return isRunningInBrowser() && window.location.host === "shiftx.com"
}

export const isLocalhost = () => {
    return isRunningInBrowser() && window.location.host.includes("localhost")
}

export const isDev = () => {
    return isRunningInBrowser() && window.location.host === "dev.shiftx.com"
}
