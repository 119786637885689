import { styled } from "@shiftx/styles"

export const FadedDividerVertical = styled.div`
    height: 100%;
    min-width: 1px;
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 49.48%,
        rgba(64, 64, 240, 0) 100%,
        rgba(0, 0, 0, 0) 100%
    );
`
export const FadedDividerHorizontal = styled.div`
    min-height: 1px;
    width: 100%;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 49.48%,
        rgba(64, 64, 240, 0) 100%,
        rgba(0, 0, 0, 0) 100%
    );
`
