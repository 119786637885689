import * as React from "react"
function SvgMenu(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21 6H3V4h18v2zM18 20H3v-2h15v2zM3 13h12v-2H3v2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgMenu
