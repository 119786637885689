export enum ActorCategoryName {
    CustomerContext = "Customer Context",
    Customer = "Customer",
    Reseller = "Reseller",
    Company = "Company",
    System = "System",
    Supplier = "Supplier",
    Competitor = "Competitor",
    Government = "Government",
    Custom = "Custom",
}
