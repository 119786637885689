export enum RunStatus {
    Setup = "setup",
    InProgress = "inProgress",
    Completed = "completed",
    Canceled = "canceled",
    Failed = "failed",
}

export const getRunStatusValues = (status: RunStatus) => {
    switch (status) {
        case RunStatus.Setup:
            return {
                label: "Setup",
                fgColor: "var(--yellow-600)",
                bgColor: "var(--yellow-100)",
            }
        case RunStatus.InProgress:
        case "active":
            return {
                label: "In progress",
                fgColor: "var(--blue-500)",
                bgColor: "var(--blue-100)",
            }
        case RunStatus.Completed:
            return {
                label: "Completed",
                fgColor: "var(--green-500)",
                bgColor: "var(--green-100)",
            }
        case RunStatus.Canceled:
            return {
                label: "Canceled",
                fgColor: "var(--fg-medium)",
                bgColor: "var(--fg-200)",
            }
        case RunStatus.Failed:
            return {
                label: "Failed",
                fgColor: "var(--red-500)",
                bgColor: "var(--red-100)",
            }
    }
}
