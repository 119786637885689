import { styled, css } from "@shiftx/styles"
import NextLink from "next/link"

type LinkProps = { disabled: boolean; shape: "rect" | "pill" }

interface LinkButtonProps {
    children: React.ComponentType | React.ReactNode
    variant?: "primary" | "secondary" | "red" | "green" | "light"
    shape?: "rect" | "pill"
    href?: string
    onClick?: () => void
    showDot?: boolean
}

const Link = styled(NextLink)<LinkProps>`
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    height: 40px;
    padding: 0 24px;
    border-radius: 4px;
    color: var(--fg-high);
    border: 1px solid var(--fg-300);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 16px;
    vertical-align: middle;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;

    :active,
    :focus {
        /* outline: none; */
    }

    ${props =>
        props.shape === "pill" &&
        css`
            border-radius: 20px;
        `}
`

export const LinkButton = ({
    children,
    href = "/",
    shape = "rect",
    variant = "secondary",
    ...props
}: LinkButtonProps) => {
    switch (variant) {
        case "secondary":
            return (
                <SecondaryLinkButton href={href} shape={shape} {...props}>
                    {children}
                </SecondaryLinkButton>
            )
        case "primary":
            return (
                <PrimaryLinkButton href={href} shape={shape} {...props}>
                    {children}
                </PrimaryLinkButton>
            )
        case "green":
            return (
                <GreenLinkButton href={href} shape={shape} {...props}>
                    {children}
                </GreenLinkButton>
            )
        case "red":
            return (
                <RedLinkButton href={href} shape={shape} {...props}>
                    {children}
                </RedLinkButton>
            )
        case "light":
            return (
                <LightLinkButton href={href} shape={shape} {...props}>
                    {children}
                </LightLinkButton>
            )
    }
}

const SecondaryLinkButton = styled(Link)`
    :hover {
        border-color: var(--fg-400);
    }
    :active,
    :focus {
        border-color: var(--fg-400);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`

const PrimaryLinkButton = styled(Link)<LinkProps>`
    color: var(--blue-500);
    border-color: var(--blue-500);

    :hover {
        border-color: var(--blue-600);
    }
    :active,
    :focus {
        border-color: var(--brand-base);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`

const GreenLinkButton = styled(Link)`
    color: var(--green-500);
    border-color: var(--green-500);
    background-color: var(--bg-primary);

    :hover {
        color: var(--green-700);
        border-color: var(--green-700);
    }
    :active,
    :focus {
        color: var(--green-700);
        border-color: var(--green-700);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`

const RedLinkButton = styled(Link)`
    color: var(--red-500);
    border-color: var(--red-500);
    background-color: var(--bg-primary);

    :hover {
        color: var(--red-700);
        border-color: var(--red-700);
    }
    :active,
    :focus {
        color: var(--red-700);
        border-color: var(--red-700);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`

const LightLinkButton = styled(Link)`
    color: hsl(0deg 0% 90%);
    border-color: hsl(0deg 0% 90%);

    :hover {
        color: hsl(0deg 0% 100%);
        border-color: hsl(0deg 0% 100%);
    }
    :active,
    :focus {
        color: hsl(0deg 0% 100%);
        border-color: hsl(0deg 0% 100%);
    }
`

const ActionLinkButton = styled(Link)`
    text-align: center;
    font-size: 15px;
    font-family: Albert Sans;
    font-weight: 500;
    line-height: 24px;

    height: auto;
    border-radius: 30px;
    padding: 12px 28px;
    border-color: transparent;
    color: var(--brand-base);
    transition: border 0.2s ease;
    background: linear-gradient(
        135deg,
        rgba(38, 38, 144, 0.04) 0%,
        rgba(64, 64, 240, 0.04) 100%
    );
    backdrop-filter: blur(8px);

    :hover {
        color: var(--brand-base);
        border-color: var(--brand-base);
    }
    :active,
    :focus {
        border-color: var(--brand-base);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`

export const LinkButtonAction = ({ children, href }) => (
    <ActionLinkButton href={href} disabled={false} shape="pill">
        {children}
    </ActionLinkButton>
)
