import { type lightTheme } from "@shiftx/styles"
import { css } from "@emotion/react"

type colorsKey = keyof typeof lightTheme.colors
type themeKeys = keyof typeof lightTheme

export const getColor = (theme, type: colorsKey) => {
    return theme?.["colors"]?.[type]
}

export const getThemeProp = (theme, prop: themeKeys) => {
    return css`
        ${theme[prop]}
    `
}
