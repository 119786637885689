import * as React from "react"
function SvgBuildings(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 6a3 3 0 013-3h5a3 3 0 013 3v15h-2V6a1 1 0 00-1-1H6a1 1 0 00-1 1v15H3V6zm3 3V7h2v2H6zm0 4v-2h2v2H6zm0 2v2h2v-2H6zm1.5 6v-2h2v2h-2zM9 7v2h2V7H9zm7 5h2v2h-2v-2zm-7-1v2h2v-2H9zm7 5h2v2h-2v-2zm-7-1v2h2v-2H9zm7-5h2a1 1 0 011 1v10h2V11a3 3 0 00-3-3h-2v2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgBuildings
