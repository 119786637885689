import * as React from "react"
function SvgLink(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.293 7.05l1.414-1.414a4 4 0 015.657 0v0a4 4 0 010 5.657l-1.414 1.414M12.707 16.95l-1.414 1.414a4 4 0 01-5.657 0v0a4 4 0 010-5.657l1.414-1.414M14.121 9.879L9.88 14.12"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    )
}
export default SvgLink
