import * as React from "react"
function SvgDirection(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 6h20L12 18 2 6z"
                fill="currentColor"
                fillOpacity={0.87}
            />
        </svg>
    )
}
export default SvgDirection
