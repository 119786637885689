import * as Sentry from "@sentry/browser"
import { analytics } from "@shiftx/analytics"
import { getIntercomAppId } from "@shiftx/analytics/utils"
import { ThemeProvider, css } from "@shiftx/styles"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { Intercom, LiveChatLoaderProvider } from "react-live-chat-loader"
import "vanilla-cookieconsent"
import "vanilla-cookieconsent/dist/cookieconsent.css"
import sanity from "../config/sanity"
import { Footer } from "../src/components/Footer"
import { Header } from "../src/components/Header"
import { GlobalStyle } from "../src/theme/global"
import { publicTheme, publicThemeDark } from "../src/theme/publicTheme"
import { pluginConfig } from "../src/utils/cookieConsentConfig"
import { isProd } from "../src/utils/isProd"
import { useGetSession } from "../src/utils/useIsvalidSession"

const isProduction = isProd()

Sentry.init({
    dsn: "https://a7888c15c4f24fbf91e0c02e0a740f2b@o163564.ingest.sentry.io/5818353",
    integrations: !isProduction
        ? [
              new Sentry.Integrations.Breadcrumbs({
                  console: false,
              }),
          ]
        : [],
    beforeSend: (event, hint) => {
        if (!isProduction) {
            console.error(
                hint.originalException || hint.syntheticException || event
            )
            return null // this drops the event and nothing will be sent to sentry
        }
        return event
    },
})

const CookieConsent = ({ setHasAccepted }) => {
    useEffect(() => {
        if (window?.initCookieConsent) {
            if (!document.getElementById("cc--main")) {
                window.CC = window.initCookieConsent()
                window.CC.run(pluginConfig({ setHasAccepted }))
            }
        }
    }, [])

    return null
}

const AppOverride = ({ Component, pageProps, appProps, ...rest }) => {
    const session = useGetSession()
    const router = useRouter()
    const [hasAccepted, setHasAccepted] = useState(false)

    useEffect(() => {
        const hasAccepted = window.CC.allowedCategory("livechat")
        setHasAccepted(hasAccepted)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            analytics.load({ session, enableIntercom: false })
            analytics.page(window.location.pathname)

            const handleRouteChange = (url, { shallow }) => {
                analytics.page(url)
            }

            router.events.on("routeChangeComplete", handleRouteChange)
        }, 2000)
    }, [])

    const intercomAppid = getIntercomAppId()

    return (
        <ThemeProvider
            theme={router.pathname === "/ai" ? publicThemeDark : publicTheme}
        >
            <LiveChatLoaderProvider
                providerKey={intercomAppid}
                provider="intercom"
            >
                <GlobalStyle />
                <CookieConsent setHasAccepted={setHasAccepted} />
                <Header
                    preHeader={pageProps.global.preHeader}
                    pages={pageProps.global.pages}
                    preview={pageProps?.sanityDoc?.__preview__}
                    isCampaign={pageProps?.content?._type === "campaignsite"}
                />
                <Component {...appProps} {...pageProps} />
                <Footer
                    isCampaign={pageProps?.content?._type === "campaignsite"}
                    useCases={pageProps.useCases}
                    compare={pageProps.compare}
                    css={css`
                        border: 0;
                    `}
                />
                {hasAccepted && <Intercom />}
            </LiveChatLoaderProvider>
        </ThemeProvider>
    )
}

const globalQuery = /* groq */ `*[_type == "navigation"][0]{preHeader, pages[]{..., subMenu[]->} }`

const useCasesQuery = /* groq */ `*[ _type in ["processMapping","customerJourney","procedures"]] | order(_createdAt asc) {
    'slug': slug.current,
    title
}`

const compareQuery = /* groq */ `*[_type == "article" && "1332cbf6-1c64-45c6-961e-6bcdc25739ae" in category[]->_id] | order(_updatedAt desc) {
    'slug': slug.current,
    title
}`
AppOverride.getInitialProps = async () => {
    const global = await sanity.fetch(globalQuery)
    const useCases = await sanity.fetch(useCasesQuery)
    const compare = await sanity.fetch(compareQuery)

    return {
        pageProps: { global, useCases, compare },
    }
}

export default AppOverride
