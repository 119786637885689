import * as React from "react"
function SvgBrokenLoop(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={12} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.057 11.781L8.277 10l2.861-2.862-.943-.943L6.391 10l2.723 2.724.943-.943z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.587 11.908A2.667 2.667 0 0113.333 16H12v1.333h1.333a4 4 0 003.213-6.384l-.959.959zm-3.082-2.575H7.333v1.334h3.838l1.334-1.334zM7.529 16.195l9.333-9.333.943.943-9.333 9.333-.943-.943z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgBrokenLoop
