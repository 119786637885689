import * as React from "react"
function SvgPath(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx={17}
                cy={12}
                r={4}
                stroke="currentColor"
                strokeWidth={2}
            />
            <path d="M13 12H3" stroke="currentColor" strokeWidth={2} />
        </svg>
    )
}
export default SvgPath
