import { graphqlClient } from "./graphqlClient"

const BookDemoQuery = /* GraphQL */ `
    mutation BookDemoMutation($input: DemoRequestInput) {
        demoRequest(input: $input) {
            ok
        }
    }
`

const ContactSalesQuery = /* GraphQL */ `
    mutation ContactSalesMutation($input: ContactSalesInput) {
        contactSales(input: $input) {
            ok
        }
    }
`

const WaitlistProcedure = /* GraphQL */ `
    mutation WaitlistProcedureMutation($input: WaitlistProcedureInput) {
        waitlistProcedure(input: $input) {
            ok
        }
    }
`

export const bookDemo = ({
    company,
    name,
    captcha,
    email,
    title,
    size,
    analytics_id,
}: {
    company: string
    email: string
    name?: string
    title?: string
    size?: string
    captcha?: string
    analytics_id?: string
}) => {
    return graphqlClient({
        query: BookDemoQuery,
        variables: {
            input: { name, email, captcha, title, company, size, analytics_id },
        },
        useEdge: true,
        usePublicKey: true,
    })
}

export const contactSales = ({ name, email, company, size, note }) => {
    return graphqlClient({
        query: ContactSalesQuery,
        variables: { input: { name, email, company, size, note } },
        useEdge: true,
        usePublicKey: true,
    })
}

export const waitlistProcedure = ({ email }: { email: string }) => {
    return graphqlClient({
        query: WaitlistProcedure,
        variables: { input: { email } },
        useEdge: true,
        usePublicKey: true,
    })
}
