import { TaskStatus } from "@shiftx/types"

export const taskStatusLabel = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.Active:
            return "In Progress"
        case TaskStatus.Completed:
            return "Completed"
        case TaskStatus.Canceled:
            return "Canceled"
        case TaskStatus.Failed:
            return "Failed"
        case TaskStatus.Paused:
            return "Paused"
        case TaskStatus.Pending:
            return "Pending"
        case TaskStatus.Skipped:
            return "Skipped"
        default:
            throw new Error(`Unsupported taskStatus '${status}'`)
    }
}
