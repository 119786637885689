import * as React from "react"
function SvgGoto(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 3h7v7h-2V6.414l-6.293 6.293-1.414-1.414L17.586 5H14V3zM5.333 5H9.6v2H5.333A.333.333 0 005 7.333v11.334c0 .184.15.333.333.333h11.334c.184 0 .333-.15.333-.333V14.4h2v4.267A2.333 2.333 0 0116.667 21H5.333A2.333 2.333 0 013 18.667V7.333A2.333 2.333 0 015.333 5z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgGoto
