import { Entity, EntityToRef, entityToRefKind } from "@shiftx/types"
import { encodeHashId } from "./encodeHashId"
import { generateSnowflakeId } from "./generateSnowflakeId"

export const generateRef = <E extends Entity>(
    entity: E,
    { id = undefined, customerId = "1" } = {}
): EntityToRef[E] => {
    const kind = entityToRefKind[entity]
    const encodedId: string =
        id || encodeHashId(generateSnowflakeId(), customerId)
    return `${kind}/${encodedId}`
}
