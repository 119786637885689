import * as React from "react"
function SvgArrowDiagonalDown(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 6l12 12m0 0V8.118M18 18H8.118"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgArrowDiagonalDown
