import * as React from "react"
function SvgPlus(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 11V4h2v7h7v2h-7v7h-2v-7H4v-2h7z"
                fill="currentColor"
                fillOpacity={0.87}
            />
        </svg>
    )
}
export default SvgPlus
