declare const window: any

import { decodeHashId } from "@shiftx/utils"
import { shouldTrackToAnalytics } from "./config"
import { trackSourceIdentified } from "./getSourceType"
import { getAnalyticsKey, log } from "./utils"

export const analyticsKey = getAnalyticsKey()

interface PageInfo {
    title?: string
    url?: string
    category?: string
    properties?: Object
    options?: Object
    callback?: () => {}
}

interface TrackProps {}

interface IdentifyProps {
    name?: string
    email?: string
    createdAt?: string
    company?: {
        name: string
        id: string
    }
    "UTM Source"?: any
    "UTM Campaign"?: any
    "UTM Medium"?: any
    "UTM Content"?: any
    "User UTM Source"?: any
    "User UTM Campaign"?: any
    "User UTM Medium"?: any
    "User UTM Content"?: any
}

interface GroupProps {
    company_id?: string
    name?: string
    createdAt?: string
    plan?: string
    licenses?: string
    users?: string
    freeTrial?: boolean
    freeTrialEnd?: string
}

export const logger = {
    breadcrumb: (data, props = {}) => {
        log(data, props, null, "log")
    },
    error: (data, props = {}) => {
        log(data, props, null, "error")
    },
    warning: (data, props = {}) => {
        log(data, props, null, "warn")
    },
    info: (data, props = {}, props2 = {}) => {
        log(data, props, props2, "log")
    },
}

const getContext = (context = {}) => {
    const membershipRef =
        window.localStorage?.["sx:lastActivatedSessionScopeRef"] ?? "{}"

    if (membershipRef) {
        const session = localStorage.getItem(`sx:session:${membershipRef}`)

        if (session) {
            const sessionJSON = JSON.parse(session)
            const customerRef = sessionJSON?.customerRef

            if (customerRef) {
                const [, groupId] = customerRef.split("/")
                return {
                    groupId: decodeHashId(groupId, { firstOnly: true }),
                    ...context,
                }
            }

            return context
        }
    }
}

export const analytics = {
    identifyAnonym: (identifyProps?: IdentifyProps, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.identify?.(identifyProps)
            logger.info("Analytics anonym identify", identifyProps)
        }
    },
    identify: (
        userId: string,
        identifyProps: IdentifyProps = {},
        options = {}
    ) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.identify(userId, identifyProps, {
                context: getContext(),
            })
            logger.info("Analytics identify", userId, identifyProps)
        }
    },

    track: (event: any, trackProps: TrackProps = {}, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.track?.(event, trackProps, {
                ...options,
                context: getContext(),
            })

            logger.info("Analytics track", {
                event,
                props: { ...trackProps, context: getContext() },
            })
        }
    },
    page: (pageName: string, pageInfo: PageInfo = {}, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.page(pageName, pageInfo, {
                ...options,
                context: getContext(),
            })
            logger.info("Analytics page", pageName)
        }
    },
    group: (
        groupId: string,
        groupProps: GroupProps = {},
        options = {},
        context = {}
    ) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.group(groupId, groupProps, {
                context: getContext(context),
            })
            logger.info("Analytics Group", groupId, {
                groupProps,
                context: getContext(context),
            })
        }
    },

    load: ({ session, enableIntercom = true }) => {
        if (window?.analytics?.load && shouldTrackToAnalytics) {
            window?.analytics?.load(analyticsKey, {
                integrations: { "Intercom Web (Actions)": enableIntercom },
            })
            trackSourceIdentified(session)
        }
    },
}
