import * as React from "react"
function SvgText(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.182 18.621l1.25-3.604h5.32l1.244 3.604h2.182L8.276 5H5.902L1 18.621h2.182zm1.849-5.334l2.008-5.813h.107l2.008 5.813H5.031zM17.959 18.848c1.689 0 2.64-.858 3.02-1.623h.079v1.396H23v-6.784c0-2.973-2.341-3.565-3.964-3.565-1.849 0-3.552.745-4.217 2.607l1.87.426c.292-.725 1.037-1.423 2.374-1.423 1.283 0 1.942.672 1.942 1.829v.046c0 .725-.745.712-2.58.925-1.936.226-3.918.732-3.918 3.053 0 2.008 1.51 3.113 3.452 3.113zm.432-1.597c-1.124 0-1.936-.505-1.936-1.49 0-1.064.945-1.443 2.095-1.596.646-.086 2.175-.26 2.461-.545v1.317c0 1.21-.964 2.314-2.62 2.314z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgText
