import "fast-text-encoding"

const bufferToHex = buffer => {
    return Array.from(new Uint8Array(buffer))
        .map(b => b.toString(16).padStart(2, "0"))
        .join("")
}

export const sha256hash = str => {
    try {
        const buffer = new TextEncoder("utf-8").encode(str)
        return crypto.subtle
            .digest("SHA-256", buffer)
            .then(hash => bufferToHex(hash))
            .catch(error => {
                throw new Error(error)
            })
    } catch (error) {
        console.error(error)
        throw error
    }
}
