export enum TextStyle {
    TitleLarge = "titleLarge",
    TitleMedium = "titleMedium",
    TextLarge = "textLarge",
    TextMedium = "textMedium",
    TextSmall = "textSmall",
    TextXSmall = "textXSmall",
    Link = "link",
    Ellipsis = "ellipsis",
}
