import { css } from "@shiftx/styles"

export const breakpoints = [600, 960, 1280]

export const breakpoint = (style: string | any[], values: any[] = []) => {
    let styles = [""]

    if (values) {
        const valuesSelected = typeof style === "string" ? values : style

        styles = valuesSelected.map((value, index) => {
            const content =
                typeof style === "string" ? `${style}: ${value};` : value

            if (index === values.length - 1) {
                return `@media (min-width: ${breakpoints[index - 1]}px) {
                    ${content};
                    }`
            } else {
                return `@media (min-width: ${
                    breakpoints[index - 1] ? breakpoints[index - 1] : 0
                }px) and 
                    (max-width: ${breakpoints[index]}px) {
                       ${content}
                    }`
            }
        })
    }

    return ` ${styles.join(" ")}`
}

const getSizeFromBreakpoint = (
    breakpointValue: number,
    breakpoints = {},
    offset = 0
) => {
    return `${breakpoints[breakpointValue] - offset}px`
}

export const lessThan =
    (breakpoint: number) =>
    (...args) =>
        css`
            @media (max-width: ${getSizeFromBreakpoint(
                    breakpoint,
                    breakpoints
                )}) {
                //@ts-ignore
                ${css(...args)}
            }
        `

export const greaterThan =
    (breakpoint: number) =>
    (...args) =>
        css`
            @media (min-width: ${getSizeFromBreakpoint(
                    breakpoint,
                    breakpoints,
                    -1
                )}) {
                //@ts-ignore
                ${css(...args)}
            }
        `

export const between =
    (firstBreakpoint: number, secondBreakpoint: number) =>
    (...args) =>
        css`
            @media (min-width: ${getSizeFromBreakpoint(
                    firstBreakpoint,
                    breakpoints
                )}) and (max-width: ${getSizeFromBreakpoint(
                    secondBreakpoint,
                    breakpoints
                )}) {
                //@ts-ignore
                ${css(...args)}
            }
        `
