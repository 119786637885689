import * as React from "react"
function SvgClose(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M8 8l8 8M16 8l-8 8" stroke="#181920" strokeWidth={1.5} />
        </svg>
    )
}
export default SvgClose
