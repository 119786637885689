import * as React from "react"
function SvgFile(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14 4H7a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1V8m-4-4v3a1 1 0 001 1h3m-4-4l4 4"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgFile
