import * as React from "react"
function SvgAdd(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 7v5m0 5v-5m0 0h5m-5 0H7"
                stroke="#484A5B"
                strokeWidth={1.5}
            />
        </svg>
    )
}
export default SvgAdd
