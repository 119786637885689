import * as React from "react"
function SvgImage(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5 19h14a1 1 0 001-1V6a1 1 0 00-1-1H5a1 1 0 00-1 1v12a1 1 0 001 1z"
                stroke="currentColor"
                strokeWidth={2}
            />
            <path
                d="M4 13l2.211-1.106a4 4 0 013.578 0l4.422 2.212a4 4 0 003.578 0L20 13"
                stroke="currentColor"
                strokeWidth={2}
            />
            <path
                d="M17 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgImage
