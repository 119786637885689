import * as React from "react"
function SvgOktaLogo(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.515 9.757C3.125 9.757 2 10.926 2 12.37c0 1.444 1.125 2.613 2.515 2.613 1.39 0 2.515-1.169 2.515-2.614 0-1.444-1.125-2.613-2.515-2.613zm0 3.923c-.695 0-1.26-.587-1.26-1.31 0-.722.565-1.31 1.26-1.31.695 0 1.26.588 1.26 1.31 0 .723-.565 1.31-1.26 1.31zM8.935 13.249c0-.208.24-.307.38-.161.63.665 1.67 1.808 1.675 1.813.015.016.03.042.09.063a.33.33 0 00.11.01h1.135c.205 0 .265-.244.17-.369l-1.88-2-.1-.105c-.215-.265-.19-.369.055-.639l1.49-1.73c.095-.125.035-.364-.175-.364h-1.03c-.04 0-.07 0-.1.01-.06.021-.085.042-.1.063-.005.005-.83.93-1.34 1.496-.14.156-.39.052-.39-.16V8.207c0-.15-.12-.208-.215-.208h-.84c-.145 0-.215.099-.215.187v6.58c0 .15.12.191.22.191h.84c.13 0 .215-.098.215-.197v-1.512h.005zM15.795 14.735l-.09-.873a.2.2 0 00-.235-.182c-.065.01-.13.016-.195.016-.67 0-1.215-.546-1.255-1.237v-1.143c0-.14.1-.255.235-.255h1.125c.08 0 .2-.073.2-.224v-.826c0-.161-.1-.244-.19-.244h-1.135a.238.238 0 01-.24-.234V8.208A.207.207 0 0013.8 8h-.835c-.105 0-.205.068-.205.203v4.256C12.795 13.872 13.91 15 15.275 15c.115 0 .225-.01.335-.026a.21.21 0 00.185-.239zM21.855 13.639c-.71 0-.82-.266-.82-1.258V9.985c0-.083-.06-.223-.22-.223h-.84a.225.225 0 00-.22.223v.11a2.41 2.41 0 00-1.24-.344c-1.39 0-2.515 1.17-2.515 2.614 0 1.445 1.125 2.614 2.515 2.614.625 0 1.195-.239 1.635-.629.235.375.615.624 1.21.63.1 0 .64.02.64-.245v-.93c0-.078-.06-.166-.145-.166zm-3.335.041c-.695 0-1.26-.587-1.26-1.31 0-.722.565-1.31 1.26-1.31.695 0 1.26.588 1.26 1.31-.005.723-.57 1.31-1.26 1.31z"
                fill="#00297A"
            />
        </svg>
    )
}
export default SvgOktaLogo
