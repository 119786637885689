import { styled } from "@shiftx/styles"
import { lessThan } from "../utils/breakpoints"

export const Title = styled.h1`
    font-family: "Albert Sans";
    font-size: 64px;
    line-height: 80px;
    font-weight: 800;
    text-align: center;
    text-wrap: balance;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    color: var(--fg-high);
    ${lessThan(1)`
        font-size: 64px;
        line-height: 80px;
    `}
    ${lessThan(0)`
        font-size: 48px;
        line-height: 60px;
    `}
`

export const Title2 = styled.h2`
    font-family: "Albert Sans";
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: var(--fg-high);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 36px;
        line-height: 36px;
    `}
    ${lessThan(0)`
        font-size: 28px;
        line-height: 36px;
    `}
`

export const Title2Gradient = styled(Title2)`
    color: transparent;
    background: linear-gradient(
        45deg,
        var(--fg-high) 0%,
        var(--fg-medium) 100%
    );
    background-size: 100%;
    background-clip: text;
    display: block;
`

export const Title3 = styled.h3`
    font-family: "Albert Sans";
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: var(--fg-high);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 24px;
        line-height: 36px;
    `}
`

export const Title4 = styled.h4`
    font-family: "Albert Sans";
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    color: var(--fg-high);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 18px;
        line-height: 28px;
    `}
`

export const Title5 = styled.h5`
    font-family: "Albert Sans";
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: var(--fg-high);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 16px;
        line-height: 28px;
    `}
`

export const SubTitle = styled.p`
    font-family: "Albert Sans";
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    color: var(--fg-high);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 16px;
        line-height: 28px;
    `}
`

export const Label = styled.p`
    font-family: "Albert Sans";
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    color: var(--fg-medium);
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
`

export const Paragraph = styled.p`
    font-family: "Albert Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: var(--fg-medium);
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 16px;
        line-height: 28px;
    `}
`

export const SubHeader = styled.h3`
    font-family: "Albert Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    text-wrap: balance;
    text-align: center;
    color: var(--brand-base);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    ${lessThan(1)`
        font-size: 18px;
        line-height: 28px;
    `}
`
