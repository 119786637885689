import * as React from "react"
function SvgBack(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16 4l-8 8 8 8" stroke="currentColor" strokeWidth={3} />
        </svg>
    )
}
export default SvgBack
