// InProgress = "inProgress",
export enum TaskStatus {
    Pending = "pending",
    Active = "active",
    Paused = "paused",
    Completed = "completed",
    // Success = "success",
    Failed = "failed",
    Canceled = "canceled", // Only happens when run is canceled
    Skipped = "skipped",
}
