import { styled } from "@shiftx/styles"

export const Link = styled.a`
    cursor: pointer;
    border-bottom: 1px solid var(--blue-500);
    padding-bottom: 2px;
    position: relative;
    color: var(--blue-500);

    :hover {
        color: var(--blue-500);
    }
`
