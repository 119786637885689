import * as React from "react"
function SvgCheckmark(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 16l8.232-10.14.768.64.768.64L10 19l-5.707-5.793L5 12.5l.707-.707L10 16z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgCheckmark
