import { css } from "@shiftx/styles"
import { lessThan } from "../utils/breakpoints"

export const Section = ({ children, refFor, ...props }) => {
    return (
        <section
            css={css`
                max-width: 1120px;
                margin: 0 auto;
                word-break: break-word;

                ${lessThan(2)`
                    padding: 0 48px;
                `}

                ${lessThan(1)`
                    padding: 0 24px;
                `};

                ${lessThan(0)`
                    padding: 0 16px;
                `};
            `}
            ref={refFor}
            {...props}
        >
            {children}
        </section>
    )
}
