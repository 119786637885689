import * as React from "react"
function SvgInbox(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83 4.106A2 2 0 017.617 3h8.764a2 2 0 011.789 1.106l3.723 7.447c.07.139.106.292.106.447v7a2 2 0 01-2 2H4a2 2 0 01-2-2v-7a1 1 0 01.106-.447l3.723-7.447zM16.381 5H7.618L4 12.236V19h16v-6.764L16.382 5z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.351 13H6v-1l.5-1h1.505c.427 0 .807.274.942.68l.004.01.025.064a4.395 4.395 0 00.667 1.106C10.14 13.456 10.881 14 12 14c1.119 0 1.86-.544 2.357-1.14a4.394 4.394 0 00.692-1.17l.004-.01a.994.994 0 01.942-.68H17.5l.5 1v1h-1.352c-.166.32-.412.729-.755 1.14C15.14 15.044 13.881 16 12 16c-1.881 0-3.14-.956-3.893-1.86A6.386 6.386 0 017.352 13z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgInbox
