import * as React from "react"
function SvgPanelOpen(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 5v14h8a1 1 0 001-1V6a1 1 0 00-1-1h-8zM7 6a3 3 0 013-3h11a3 3 0 013 3v12a3 3 0 01-3 3H10a3 3 0 01-3-3V6z"
                fill="currentColor"
            />
            <path
                d="M0 7.381a.5.5 0 01.884-.32l3.85 4.619a.5.5 0 010 .64l-3.85 4.62A.5.5 0 010 16.618V7.381z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgPanelOpen
