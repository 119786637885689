import * as React from "react"
function SvgStars(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 4l2.667 5.333L17 12l-5.333 2.667L9 20l-2.667-5.333L1 12l5.333-2.667L9 4zm0 4.944l-1.019 2.037L5.944 12l2.037 1.019L9 15.056l1.019-2.037L12.056 12l-2.037-1.019L9 8.944zM19 1l1.333 2.667L23 5l-2.667 1.333L19 9l-1.333-2.667L15 5l2.667-1.333L19 1zM19 15l1.333 2.667L23 19l-2.667 1.333L19 23l-1.333-2.667L15 19l2.667-1.333L19 15z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgStars
