import { PortableTextReactComponents, toPlainText } from "@portabletext/react"
import slugify from "slugify"
import { greaterThan } from "../utils/breakpoints"
import { Image } from "./Image"
import { css } from "@shiftx/styles"

export const serializers: Partial<PortableTextReactComponents> = {
    block: {
        h2: ({ value, children }) => {
            const plaintext = toPlainText(value)
            const slug = slugify(plaintext).toLowerCase()

            return (
                <h2>
                    <a id={slug}>{children}</a>
                </h2>
            )
        },
        h3: ({ value, children }) => {
            const plaintext = toPlainText(value)
            const slug = slugify(plaintext).toLowerCase()

            return (
                <h3>
                    <a id={slug}>{children}</a>
                </h3>
            )
        },
    },
    types: {
        image: ({ value }) => {
            return <Image image={value.asset} alt={value.alt} />
        },
        youtube: ({ value }) => {
            const { url } = value
            return (
                <div
                    css={css`
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        height: 0;
                    `}
                >
                    <iframe
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 0px;
                        `}
                        loading="lazy"
                        src={url}
                        allowFullScreen
                    ></iframe>
                </div>
            )
        },
        shiftxFlow: ({ value }) => {
            const { url } = value
            return (
                <div
                    css={css`
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        height: 0;
                        ${greaterThan(
                            1
                        )`margin-left: calc(-100vw / 2 + 720px / 2);
                        margin-right: calc(-100vw / 2 + 720px / 2);`}
                    `}
                >
                    <iframe
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 0px;
                        `}
                        loading="lazy"
                        src={`https://shiftx.com/embed?url=${decodeURI(url)}`}
                        allowFullScreen
                    ></iframe>
                </div>
            )
        },
        table: ({ value }) => {
            return (
                <div
                    css={css`
                        display: grid;
                        font-size: 13px;
                        border-bottom: 1px solid #ccc;
                    `}
                >
                    {value.rows.map((row, index) => {
                        return (
                            <div
                                css={css`
                                    display: grid;
                                    grid-template-columns: 1fr 1fr 1fr;
                                    border: 1px solid #ccc;
                                    border-bottom: 0;
                                    border-left: 0;

                                    ${index === 0 &&
                                    css`
                                        font-weight: 600;
                                        background-color: #f7f7f7;
                                    `}
                                `}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <div
                                            css={css`
                                                border-left: 1px solid #ccc;
                                                padding: 4px 6px;
                                            `}
                                        >
                                            {cell}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )
        },
        asset: ({ value }) => {
            const { autoplay = false, description } = value

            let url = value.asset?.url
            if (value.asset?.derived?.[0]) {
                url = value.asset?.derived?.[0].secure_url
            }

            if (!url) {
                return null
            }

            return (
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    <video
                        autoPlay={autoplay}
                        css={css`
                            width: 100%;
                            border: 1px solid var(--fg-200);
                        `}
                    >
                        <source src={url} type="video/mp4" />
                    </video>
                    {description && (
                        <div
                            css={css`
                                text-align: center;
                                font-size: 13px;
                            `}
                        >
                            {description}
                        </div>
                    )}
                </div>
            )
        },
    },
}
