import { sxHashId } from "./sxHashId"

export const encodeHashId = (refOrId, customerId = undefined) => {
    const id = typeof refOrId === "string" ? refOrId : refOrId.id
    if (Array.isArray(id)) {
        const [entityId, ...rest] = id
        const ints = [BigInt(entityId)]
        if (customerId) ints.push(BigInt(customerId))
        ints.push(...rest.map(str => BigInt(str)))
        return sxHashId.encode(...ints)
    } else {
        const ints = [BigInt(id)]
        if (customerId) ints.push(BigInt(customerId))
        return sxHashId.encode(...ints)
    }
}
