import * as React from "react"
function SvgDraghandler(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={8.5} cy={8.5} r={1.5} fill="#181920" />
            <circle cx={15.5} cy={8.5} r={1.5} fill="#181920" />
            <circle cx={8.5} cy={15.5} r={1.5} fill="#181920" />
            <circle cx={15.5} cy={15.5} r={1.5} fill="#181920" />
        </svg>
    )
}
export default SvgDraghandler
