import * as React from "react"
function SvgSuccess(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={12} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.667 14.667l5.487-6.76.513.426.512.427-6.512 7.907-3.805-3.862.471-.472.472-.471 2.862 2.805z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgSuccess
