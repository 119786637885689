import * as React from "react"
function SvgSortUp(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="currentColor"
                d="M12 6h10v2H12zM5 10h2v8H5zM12 11h6v2h-6zM12 16h3v2h-3zM2 10h8L6 5l-4 5z"
            />
        </svg>
    )
}
export default SvgSortUp
