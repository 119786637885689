import * as React from "react"
function SvgUndo(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.707 4.707L9.293 3.293 3.586 9l5.707 5.707 1.414-1.414L7.414 10H14a4 4 0 010 8h-2v2h2a6 6 0 000-12H7.414l3.293-3.293z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgUndo
