import ky from "ky"
import { sha256hash } from "./sha256hash"
const { v4: uuidv4 } = require("uuid")

export interface GraphQLProps {
    query: string
    variables?: any
    useEdge?: boolean
    usePublicKey?: boolean
    customHeaders?: { [key: string]: string }
}

const generateHeaders = async (
    body,
    useEdge,
    usePublicKey,
    customHeaders = {}
) => {
    const headers = {
        "Content-Type": "application/json",
        "Payload-Hash": await sha256hash(body),
        "SX-Edge": useEdge ? "1" : "0",
        "SX-Public": usePublicKey ? "1" : "0",
        "SX-TraceId": uuidv4(),
        ...customHeaders,
    }
    return headers
}

export async function graphqlClient({
    query,
    variables,
    useEdge = false,
    usePublicKey = false,
    customHeaders,
}: GraphQLProps) {
    const body = JSON.stringify({ query, variables })
    return ky
        .post("/api/graphql", {
            timeout: 15000,
            body,
            headers: await generateHeaders(
                body,
                useEdge,
                usePublicKey,
                customHeaders
            ),
            credentials: "include",
        })
        .json()
}
