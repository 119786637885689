import * as React from "react"
function SvgPanelToggle(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 5v14h12a1 1 0 001-1V6a1 1 0 00-1-1H8zM1 6a3 3 0 013-3h16a3 3 0 013 3v12a3 3 0 01-3 3H4a3 3 0 01-3-3V6z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgPanelToggle
