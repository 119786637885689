import * as React from "react"
function SvgGlobe(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15 3.512A9 9 0 003.512 15M15 3.512L12 6l-1 3.5 4.5 1.5.5 4 3-4 1.876-.5M15 3.512a9.013 9.013 0 015.876 6.988m0 0A9 9 0 017.873 20m-4.36-5L6 12.5 9.5 15l-1.627 5m-4.36-5a9.029 9.029 0 004.36 5"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgGlobe
