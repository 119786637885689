import { decodeHashId } from "@shiftx/utils"
import { analytics } from "./client"
import { Events2 } from "./events"
import { getUrlParams } from "./utils"

const getQueryParams = () => {
    const params = getUrlParams<{
        utm_source?: string
        utm_campaign?: string
        utm_medium?: string
        ref?: string
        source?: string
    }>(window.location.search)

    return params
}

const getReferrer = () => {
    if (document.referrer) {
        return { referrer_url: document.referrer, link: document.location.href }
    }

    return null
}

const organicReferrer = [
    "https://www.google.com/",
    "https://google.com/",
    "https://www.google.no/",
    "https://www.bing.com/",
    "https://yandex.com/",
]

const getSourceType = ({ queryParams, referrer }) => {
    if (queryParams.utm_source === "adwords") {
        return { type: "ads", ...queryParams, ...referrer }
    } else if (queryParams.utm_source) {
        return {
            type: "utm",
            referrer_source: queryParams.utm_source,
            ...referrer,
            ...queryParams,
        }
    } else if (queryParams.ref) {
        return {
            type: "referrer",
            referrer_source: queryParams.ref,
            ...queryParams,
            ...referrer,
        }
    } else if (referrer) {
        if (referrer?.referrer_url.includes("shiftx.com")) {
            return { type: "internal", ...referrer, ...queryParams }
        } else if (organicReferrer.includes(referrer.referrer_url)) {
            return { type: "organic", ...referrer, ...queryParams }
        } else {
            return { type: "referrer", ...referrer, ...queryParams }
        }
    }

    return { type: "direct" }
}

const getSource = () => {
    const queryParams = getQueryParams()
    const referrer = getReferrer()
    const type = getSourceType({ queryParams, referrer })

    return type
}

export const trackSourceIdentified = session => {
    const ajs_anonymous_id = localStorage.getItem("ajs_anonymous_id")
    if (session) {
        const decoded = session?.user?.id
            ? decodeHashId(session.user.id, { firstOnly: true })
            : undefined
        analytics.identify(decoded, {})
    } else {
        analytics.identifyAnonym()
        if (ajs_anonymous_id) {
            analytics.track(Events2.client.SourceIdentified, getSource())
        } else {
            analytics.track(Events2.client.InitialSourceIdentified, getSource())
        }
    }
}
