import * as React from "react"
function SvgShard(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 3h-6v8h8V5a2 2 0 00-2-2zm0 6h-4V5h4v4zM5 7a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-6h-6V7H5zm0 2h4v6h6v4H5V9z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgShard
