import React from "react"
import { Image } from "./Image"
import { assetUrl, Video } from "./Video"
import { css } from "@shiftx/styles"

export const Asset = ({ asset, ...props }) => {
    if (asset?._type === "cloudinary" || asset?._type === "cloudinary.asset") {
        if (asset.resource_type === "video") {
            return (
                <Video
                    src={assetUrl(asset)}
                    asset={asset}
                    autoPlay={true}
                    playsInline={true}
                    {...props}
                />
            )
        } else if (asset.resource_type === "image") {
        }
    } else if (asset?._type === "image") {
        return <Image image={asset} alt={asset.alt} {...props} />
    } else if (asset?._type === "youtube") {
        return (
            <div
                css={css`
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;
                    border: 1px solid var(--fg-100);
                    border-radius: 24px;
                    overflow: hidden;
                `}
            >
                <iframe
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 0px;
                    `}
                    loading="lazy"
                    src={asset.url}
                    allowFullScreen
                ></iframe>
            </div>
        )
    }

    return null
}
