import * as React from "react"
function SvgTimeline(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20 12a8 8 0 10-16 0M20 12a8 8 0 10-16 0M20 12a7.965 7.965 0 01-1.691 4.92"
                stroke="currentColor"
                strokeWidth={2}
            />
            <path
                d="M20 12a8 8 0 01-13.292 6"
                stroke="currentColor"
                strokeWidth={2}
                strokeDasharray="2 1.75"
            />
            <path d="M11.5 7.5v5l3 2" stroke="currentColor" strokeWidth={2} />
        </svg>
    )
}
export default SvgTimeline
