import * as React from "react"
function SvgFilter(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="currentColor"
                fillOpacity={0.87}
                d="M1.332 3.334h13.333v1.333H1.332zM4 7.334h8v1.333H4zM6 11.334h4v1.333H6z"
            />
        </svg>
    )
}
export default SvgFilter
