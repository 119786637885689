import * as React from "react"
function SvgItalic(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.523 6.344h2.578l-1.79 10.767H7.733L7.421 19h7.344l.312-1.89h-2.571l1.79-10.766h2.57l.313-1.89H9.836l-.313 1.89z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgItalic
