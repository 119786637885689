import { format } from "date-fns"
import Link from "next/link"
import { greaterThan, lessThan } from "../utils/breakpoints"
import { Section } from "./Section"
import { css, styled } from "@shiftx/styles"

const FooterContainer = styled(Section)`
    margin-top: var(--section-margin);
    display: grid;
    grid-gap: 1.5rem;

    ${greaterThan(1)`
        grid-template-columns: 0.5fr 3fr;
    `};

    a:hover {
        color: var(--brand-base);
    }
`

const FooterList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    li {
        margin-top: 16px;
    }
`
const FooterTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`

export const Footer = ({ useCases, compare, isCampaign = false }) => {
    return (
        <footer css={css``}>
            {!isCampaign && (
                <FooterContainer>
                    <div
                        css={css`
                            color: var(--fg-high);
                        `}
                    >
                        <svg
                            width="95"
                            height="40"
                            viewBox="0 0 95 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M71.5538 11.111H66.776L72.5538 19.9999L66.776 28.8888H71.5538L74.9983 23.111L78.5538 28.8888H83.3316L77.4983 19.9999L83.2205 11.111H78.5538L75.0538 16.8888L71.5538 11.111Z"
                                fill="currentColor"
                            />
                            <path
                                d="M63.2205 12.8888H59.8872V14.4443C59.8872 15.7777 59.2205 16.4443 57.8872 16.4443H57.2205V19.6666H59.4427V24.7777C59.4427 28.4443 61.6649 29.0554 63.6649 29.0554C64.9983 29.0554 65.6649 28.7777 65.6649 28.7777V25.5554C63.9983 25.8888 63.2205 25.6666 63.2205 24.4443V19.6666H65.6649V16.4443H63.2205V12.8888Z"
                                fill="currentColor"
                            />
                            <path
                                d="M54.4983 10.5554C51.4427 10.5554 49.776 12.4443 49.776 15.3332V16.4443H47.776V19.6666H49.776V28.8888H53.5538V19.6666H56.1094V16.4443H53.5538L53.5538 15.5554C53.5537 13.6666 55.2205 13.611 56.3316 13.9443V10.7777C56.3316 10.7777 55.6649 10.5554 54.4983 10.5554Z"
                                fill="currentColor"
                            />
                            <path
                                d="M43.4427 14.8888C44.67 14.8888 45.6649 13.8939 45.6649 12.6666C45.6649 11.4393 44.67 10.4443 43.4427 10.4443C42.2154 10.4443 41.2205 11.4393 41.2205 12.6666C41.2205 13.8939 42.2154 14.8888 43.4427 14.8888Z"
                                fill="currentColor"
                            />
                            <path
                                d="M45.3316 28.8888V16.4443H41.5538V28.8888H45.3316Z"
                                fill="currentColor"
                            />
                            <path
                                d="M30.5538 10.7777H26.776V28.8888H30.5538V21.7777C30.5538 20.7221 31.2205 19.5554 32.6094 19.5554C33.8872 19.5554 34.6649 20.2221 34.6649 21.8888V28.8888H38.4427V20.9999C38.4427 18.111 36.8872 16.111 33.776 16.111C32.276 16.111 31.1094 16.5554 30.5538 17.2221V10.7777Z"
                                fill="currentColor"
                            />
                            <path
                                d="M20.6649 16.2221L23.6649 14.3332C22.6649 12.2221 20.7607 10.6666 17.4427 10.6666C13.6649 10.6666 11.1094 13.3332 11.1094 16.3332C11.1094 19.5554 13.6852 21.1875 15.8316 21.5554C16.0154 21.5869 16.2036 21.6175 16.3938 21.6483C18.2162 21.9437 20.2205 22.2685 20.2205 23.7777C20.2205 24.3888 19.8316 25.6666 17.776 25.6666C16.0545 25.6666 14.8872 24.9443 14.1649 23.6666L11.1094 25.5554C12.1094 27.3332 13.8944 29.111 17.6649 29.111C21.9983 29.111 24.2205 26.2221 24.2205 23.4999C24.2205 20.7221 22.3577 18.6824 18.3872 18.0554C16.276 17.7221 14.9983 17.4999 14.9983 16.0554C14.9983 15.7221 15.3316 14.1666 17.4427 14.1666C18.7526 14.1666 19.8872 14.6666 20.6649 16.2221Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>

                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
                            ${lessThan(1)`
                                grid-template-columns: repeat(
                                    auto-fit,
                                    minmax(8rem, 1fr)
                                );
                                grid-gap: 2.5rem;
                            `};

                            grid-gap: 1.5rem;
                        `}
                    >
                        <div>
                            <FooterTitle>Use cases</FooterTitle>
                            <FooterList>
                                {useCases.map((useCase, index) => {
                                    return (
                                        <li key={index}>
                                            <Link
                                                href={`/use-case/${useCase.slug}`}
                                            >
                                                {useCase.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </FooterList>
                        </div>
                        <div>
                            <FooterTitle>Product</FooterTitle>
                            <FooterList>
                                <li>
                                    <Link href="/features/map">Features</Link>
                                </li>
                                <li>
                                    <Link href="/why">Why ShiftX?</Link>
                                </li>
                                <li>
                                    <Link href="/integrations">
                                        Integrations
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/templates">Templates</Link>
                                </li>
                            </FooterList>
                        </div>
                        <div>
                            <FooterTitle>Resources</FooterTitle>
                            <FooterList>
                                <li>
                                    <Link href="/customers">
                                        Customer cases
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/blog">Blog</Link>
                                </li>
                                <li>
                                    <Link href="https://intercom.help/shiftx/en/">
                                        Support
                                    </Link>
                                </li>
                                {compare.map((article, index) => {
                                    return (
                                        <li key={index}>
                                            <Link
                                                href={`/blog/${article.slug}`}
                                            >
                                                {article.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </FooterList>
                        </div>

                        <div>
                            <FooterTitle>Company</FooterTitle>
                            <FooterList>
                                <li>
                                    <Link href="/about">About us</Link>
                                </li>
                                <li>
                                    <Link href="/careers">Careers</Link>
                                </li>
                                <li>
                                    <Link href="/contact">Contact</Link>
                                </li>
                            </FooterList>
                        </div>
                        <div>
                            <FooterTitle>Get started</FooterTitle>
                            <FooterList>
                                <li>
                                    <Link href="/signup">Try for free</Link>
                                </li>

                                <li>
                                    <Link href="/demo">Book a demo</Link>
                                </li>
                            </FooterList>
                        </div>
                    </div>
                </FooterContainer>
            )}
            <Section
                css={css`
                    display: flex;
                    margin-top: var(--section-margin);
                    justify-content: space-between;
                    margin-bottom: 40px;
                    ${lessThan(0)`flex-direction: column`}
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--fg-medium);
                `}
            >
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    © {format(new Date(), "yyy")} ShiftX AS
                </div>
                <div
                    css={css`
                        display: flex;
                        ${lessThan(0)`
                        flex-direction: column;                    
                        margin-top: 16px;
                    `};
                    `}
                >
                    <div
                        css={css`
                            margin-right: 2rem;
                            cursor: pointer;
                        `}
                    >
                        <div data-cc="c-settings">Manage cookies</div>
                    </div>
                    <div
                        css={css`
                            margin-right: 2rem;
                        `}
                    >
                        <Link href="/terms-of-service">Terms</Link>
                    </div>
                    <div
                        css={css`
                            margin-right: 2rem;
                        `}
                    >
                        <Link href="/privacy-policy">Privacy</Link>
                    </div>
                    <div>
                        <Link href="https://status.shiftx.com/">
                            System status
                        </Link>
                    </div>
                </div>
            </Section>
        </footer>
    )
}
