import * as React from "react"
function SvgInprogress(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.014 7.665A9.961 9.961 0 0122 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2a9.998 9.998 0 019.014 5.665zM4 12a8 8 0 018-8v16a8 8 0 01-8-8z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgInprogress
