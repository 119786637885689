import queryString from "query-string"

export const urlWithHostFromEnv = (path, query = undefined) => {
    const host = process.env.HOST
    const searchParams = query
        ? queryString.stringify(JSON.parse(JSON.stringify(query)))
        : undefined

    const protocol = host.match(/^localhost/) ? "https" : "https"

    return (
        `${protocol}://${host}${path}` +
        (searchParams ? `?${searchParams}` : "")
    )
}
