import * as React from "react"
function SvgAvatar(props) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={20} cy={20} r={20} fill="#E6EBEF" />
            <circle cx={20} cy={15} r={5} fill="#fff" />
            <path d="M12 24h16l2 6H10l2-6z" fill="#fff" />
        </svg>
    )
}
export default SvgAvatar
