import * as React from "react"

export const ShiftX = props => {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 0H0v40h40V0zM23.556 11.111h5.333c0 1.753-.411 3.4-1.132 4.827l-4.573-2.846c.238-.596.372-1.269.372-1.98zM11.11 28.89c0-1.753.411-3.4 1.132-4.827l4.573 2.846a5.351 5.351 0 00-.372 1.98h-5.333zm0-17.778h5.334c0 3.196 2.514 4.956 5.282 6.894 3.39 2.372 7.162 5.012 7.162 10.884h-5.333c0-3.196-2.515-4.956-5.283-6.893-3.39-2.373-7.162-5.013-7.162-10.885z"
                fill="currentColor"
            />
        </svg>
    )
}
