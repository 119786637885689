import * as React from "react"
function SvgChevronRight(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.707 19.707l-1.414-1.414L13.586 12 7.293 5.707l1.414-1.414L16.414 12l-7.707 7.707z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgChevronRight
