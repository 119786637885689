import { type Ref, type RefKind } from "@shiftx/types"

export const parseRef = <E extends RefKind>(
    string: Ref
): { id: string; kind: E; timestamp: string | undefined } => {
    if (!string) return null
    const [, kind, id, timestamp, versionId] = string.match(
        /^(\w+)\/(\w+)@?([^(]*)\(?([^)]*)\)?$/
    )

    const res = {
        id,
        kind,
        timestamp: timestamp,
    }

    if (versionId) res.versionRef = { id: versionId, kind: "Version" }
    return res
}
