import * as React from "react"
function SvgStop(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="#181822" d="M6 6h12v12H6z" />
        </svg>
    )
}
export default SvgStop
