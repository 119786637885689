import * as React from "react"
function SvgUploadStar(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 19v-3h2v3h14v-3h2v3a2 2 0 01-2 2H5a2 2 0 01-2-2zM12 2l2.333 4.667L19 9l-4.667 2.333L12 16l-2.333-4.667L5 9l4.667-2.333L12 2zm0 4.326l-.891 1.783L9.326 9l1.783.891.891 1.783.891-1.783L14.674 9l-1.783-.891L12 6.326z"
                fill="#212121"
            />
        </svg>
    )
}
export default SvgUploadStar
