import * as React from "react"
function SvgHand(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.488 5.49c.283-1.057.913-1.153 1.218-1.071.306.082.803.48.52 1.537l-.603 2.25a3.619 3.619 0 00-.019.068l-1.241 4.636 1.738.466 1.258-4.693c.29-1.004.903-1.095 1.203-1.014.305.082.803.48.52 1.537L17.529 15c-.585 2.183-1.492 3.388-2.45 3.99-.95.595-2.097.693-3.372.351-2.586-.692-3.849-2.102-5.052-4.186L5.4 12.98l-.007-.012-.003-.005-1.28-2.216c-.22-.381-.221-.661-.174-.838a.727.727 0 01.348-.444.752.752 0 01.571-.076c.182.05.422.191.635.561l1.697 2.939 1.087 1.88L10.7 5.718c.283-1.057.912-1.153 1.218-1.07.305.081.803.48.52 1.536l-1.553 5.796 1.739.466 1.864-6.955zm-.981-1.942c.697-.806 1.696-1.127 2.665-.868 1.284.344 2.102 1.598 1.904 3.179.313-.037.634-.014.952.071 1.433.384 2.286 1.901 1.792 3.742l-1.553 5.795c-.657 2.454-1.763 4.126-3.232 5.048-1.478.928-3.171 1-4.794.566-3.21-.86-4.803-2.701-6.145-5.025-.632-1.095-.945-1.638-1.26-2.181l-.002-.004-.004-.008-1.28-2.216c-.426-.737-.54-1.504-.355-2.199a2.527 2.527 0 011.197-1.547 2.552 2.552 0 011.936-.249c.69.189 1.297.665 1.72 1.398l.611 1.058 1.302-4.856c.493-1.84 1.99-2.729 3.422-2.344.436.116.817.337 1.124.64z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgHand
