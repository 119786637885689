import { isRunningInBrowser } from "@shiftx/utils"
import {
    analyticsDevKey,
    analyticsProdKey,
    intercomDevAppId,
    intercomProdAppId,
    printToConsole,
    shouldTrackToAnalytics,
} from "./config"

declare const window: any

export const log = (
    message: string | number,
    props = {},
    props2 = {},
    type
) => {
    if (printToConsole) {
        console[type](message, props, props2)
    }
}

export const isProd = () => {
    return isRunningInBrowser() && window?.location?.host === "shiftx.com"
}

export const isTest = () => {
    return process?.env?.NODE_ENV === "test"
}

export const getAnalyticsKey = () => {
    if (shouldTrackToAnalytics) {
        if (isProd()) {
            return analyticsProdKey
        } else {
            return analyticsDevKey
        }
    }
}

export const getIntercomAppId = () => {
    if (shouldTrackToAnalytics) {
        if (isProd()) {
            return intercomProdAppId
        } else {
            return intercomDevAppId
        }
    }
}

export const getUrlParams = <G>(string: string): G => {
    const params = new URLSearchParams(string)
    return ([...params.entries()] as any).reduce((object, [key, value]) => {
        object[key] = value
        return object
    }, {})
}
