import { createClient } from "@sanity/client"

import imageUrlBuilder from "@sanity/image-url"
import config from "./config.mjs"

export const client = createClient(config)

export const imageBuilder = imageUrlBuilder(client)

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
    ...config,
    useCdn: false,
    withCredentials: true,
    // token: process.env.SANITY_API_TOKEN,
})

export const sanityConfig = createClient({
    ...config,
    useCdn: true,
})

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview = false) => {
    return usePreview ? previewClient : client
}

export default client
