import * as React from "react"
function SvgActor(props) {
    return (
        <svg
            width={448}
            height={512}
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M225.012 255.014c66.285 0 120.007-53.731 120.007-120.007C345.019 68.731 291.297 15 225.012 15S105.005 68.731 105.005 135.007c0 66.276 53.722 120.007 120.007 120.007zm47.534 45.003h-95.068C87.764 300.017 15 372.771 15 462.495 15 480.44 29.55 495 47.496 495h355.051c17.945.028 32.477-14.504 32.477-32.505 0-89.724-72.754-162.478-162.478-162.478z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgActor
