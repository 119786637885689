export const Events = {
    // Delete/Archive events
    DeleteCommentThread: "DeleteCommentThread",
    DeleteReply: "DeleteReply",
    DeleteFavorite: "deleteFavorite",
    DeleteIssue: "DeleteIssue",
    DeletePath: "deletePath",
    DeleteProcess: "deleteProcess",
    RemoveProcessMember: "Remove process member",
    DeleteReference: "deleteReference",
    DeleteSplit: "deleteSplit",
    DeleteStep: "deleteStep",
    ChangeVersion: "Change version",
    OpenTemplate: "Open template",
    TemplateNavigation: "Navigate to template",
    SignupNavigation: "Navigate to signup",
}

export const Events2 = {
    persisted: {
        updateProcessGlobalRole: {
            name: "Change globalrole",
            props: items => {
                return { globalRole: items[0].item.ProcessData.globalRole }
            },
        },
        archive: {
            name: "Archive",
            props: items => {
                const item = items.find(item => item.kind === "archive")
                return {
                    type: item.ref.split("/")[0],
                }
            },
        },
        updateProcessItemNarrative: {
            name: "Update narrative",
        },
        createStep: {
            name: "createStep",
        },

        updateStep: {
            name: ({ input }) => {
                if (input.duration) {
                    return "Update step duration"
                } else if (input.channelId) {
                    return "Update channel"
                }
                return "updateStep"
            },
        },

        createSplit: {
            name: "createSplit",
            props: items => {
                const split = items.find(item => {
                    return item?.item?.SplitData?.kind === "split"
                })

                if (split) {
                    return { splitType: split.item.SplitData.splitType }
                }
            },
        },
        updateSplit: {
            name: "Update split",
        },

        updatePath: {
            name: ({ input }) => {
                if (input?.onSuccess?.kind === "done") {
                    return "createEnd"
                } else if (input?.onSuccess?.kind === "none") {
                    return "deleteEnd"
                } else if (input?.onSuccess?.kind === "loop") {
                    return "CreateLoop"
                } else if (input?.probability) {
                    return "Update split probability"
                }

                return "Update path"
            },
        },
        DeleteLoop: "DeleteLoop", //TODO: Part of updatePath

        createReference: {
            name: "createReference",
        },
        createPath: {
            name: "createPath",
        },
        createTag: {
            name: "createTag",
        },
        createVersion: {
            name: "createVersion",
        },
        createIssue: {
            name: "createIssue",
        },
        createActor: {
            name: "createActor",
            props: items => {
                return { icon: items[0].item.ActorData.icon }
            },
        },
        updateActor: {
            name: "Update actor",
            props: items => {
                return { icon: items[0].item.ActorData.icon }
            },
        },
        createProcess: {
            name: "createProcess",
        },
        createAccessRelation: {
            name: "Add process member",
        },
        updateAccessRelation: {
            name: "Change process member role",
        },

        createComment: { name: "CreateCommentThread" },
        updateComment: { name: "UpdateComment" },
        CreateReply: { name: "CreateReply" }, //TODO: Will not work
        createFavorite: { name: "createFavorite" },
        SignUpComplete: { name: "Signup Complete" },

        CreateContentBlock: { name: "Create content block" },
        UpdateContentBlock: { name: "Update content block" },
        DeleteContentBlock: { name: "Delete content block" },

        CreatePublicLink: { name: "createPublicLink" },
        DeletePublicLink: { name: "deletePublicLink" },
        SwitchMembership: { name: "Switch membership" },
        CreateWorkspace: { name: "Create workspace" },
        ChangePlan: { name: "changePlan" },
        BillingChange: { name: "Billing change" },
        Purchase: { name: "Purchase" },
        CreateInvite: { name: "createInvite" },
        TransferMembership: { name: "Transfer membership" },
    },
    client: {
        AddActor: "addActor",
        ChangeDetailTab: "Change detail tab",
        CopyEmbedCode: "Copy embed code",
        DownloadPng: "downloadPNG",
        Filter: "Filter",
        InitialSourceIdentified: "Initial Source Identified",
        Navigate: "Navigate",
        OnboardingStart: "Onboarding start",
        OnboardingSkip: "Onboarding skip",
        OnboardingStepFinished: "Onboarding step finished",
        OnboardingEnd: "Onboarding end",
        SelectItem: "SelectItem",
        ShowInviteUser: "Show invite user",
        ShowModal: "Show modal",
        SourceIdentified: "Source Identified",
        OpenVersion: "Open version",

        // Publish
        PublishNotifyUsers: "publishNotifyUsers",
        PublishNotifyUsersToggle: "publishNotifyUsersToggle",
        PublishNotifyUsersAddUser: "publishNotifyUsersAddUser",
        PublishNotifyUsersRemoveUser: "publishNotifyUsersRemoveUser",

        // Invite
        CreateInvite: "createInvite",
        DeleteInvite: "deleteInvite",
        ResendInvite: "resendInvite",

        Attribution: "Attribution",
        LogIn: "Login",
        SignUpStart: "Signup Start",

        SwitchMembership: "Switch membership",
        SignUpComplete: "Signup Complete",

        EmbededProcessViewed: "Embeded process viewed",
        PublicProcessViewed: "Public process viewed",

        //Run
        CreateRun: "Create run",
        CompleteTask: "Complete task",
        ValidateRun: "Validate run",
        StartRun: "Start run",
        AssignUser: "Assign user to task",

        //Setup
        SetupStepDone: "Setup step done",
        SetupDone: "Setup done",

        //AI
        GenerateAI: "Generate AI",
        EditInShiftx: "Edit in ShiftX",
        SelectExamplePrompt: "Select example prompt",
    },
}
