import { IssueStatus } from "@shiftx/types"

export const issueStatusLabel = (status: IssueStatus) => {
    switch (status) {
        case IssueStatus.Blocked:
            return "Blocked"
        case IssueStatus.Todo:
            return "To do"
        case IssueStatus.Solved:
            return "Done"
        case IssueStatus.Discarded:
            return "Canceled"
        case IssueStatus.InProgress:
            return "In progress"
        default:
            throw new Error(`Unsupported issueStatus '${status}'`)
    }
}
