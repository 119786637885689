import * as React from "react"
function SvgHelp(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 12a8 8 0 1116 0 8 8 0 01-16 0zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm.568 12.165h-1.75v-.156c.005-1.616.439-2.11 1.214-2.6.568-.36 1.007-.761 1.007-1.366 0-.642-.503-1.057-1.127-1.057-.605 0-1.163.401-1.19 1.154H8.837c.041-1.847 1.408-2.724 3.084-2.724 1.832 0 3.134.942 3.134 2.558 0 1.085-.563 1.763-1.426 2.276-.73.438-1.053.858-1.062 1.758v.158zm.268 1.847a1.123 1.123 0 01-1.108 1.108 1.103 1.103 0 110-2.207c.59 0 1.103.494 1.108 1.1z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgHelp
