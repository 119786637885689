export enum TextStyle {
    TextLarge = "textLarge",
    TextMedium = "textMedium",
    TextSmall = "textSmall",
    TextXSmall = "textXSmall",
    Link = "link",
    Ellipsis = "ellipsis",
}

const colors = {
    bgPrimary: "hsl(0 0% 100%)",
    bgSecondary: "#F5F5F5",
    bgElevated: "hsl(0 0% 100%)",
    brandLow: "#E8EEFD",
    brandBase: "hsla(240, 85%, 60%)",
    brandHigh: "#3143B6",
    onBrand: "hsl(0 0% 100%)",
    fg100: "hsl(0 0% 0% / 0.04)",
    fg100Solid: "hsl(0 0% 96%)",
    fg200: "hsl(0 0% 0% / 0.08)",
    fg200Solid: "hsl(0 0% 92%)",
    fg300: "hsl(0 0% 0% / 0.12)",
    fg300Solid: "hsl(0 0% 88%)",
    fg400: "hsl(0 0% 0% / 0.18)",
    fg400Solid: "hsl(0 0% 82%)",
    fg500: "hsl(0 0% 0% / 0.24)",
    fg500Solid: "hsl(0 0% 76%)",
    fgLow: "hsl(0 0% 0% / 0.38)",
    fgLowSolid: "hsl(0 0% 62%)",
    fgMedium: "hsl(0 0% 0% / 0.6)",
    fgMediumSolid: "hsl(0 0% 40%)",
    fgHigh: "hsl(0 0% 0% / 0.87)",
    fgHighSolid: "hsl(0 0% 13%)",
    fgColor: "hsl(0 0% 100%)",
}

const textLarge = {
    fontSize: "15px",
    lineHeight: "24px",
    color: colors.fgHigh,
    fontWeight: 600,
}

const textMedium = {
    fontSize: "13px",
    lineHeight: "24px",
    color: colors.fgHigh,
    fontWeight: 600,
}

const textSmall = {
    fontSize: "12px",
    lineHeight: "20px",
    color: colors.fgHigh,
    fontWeight: 500,
}

const textXSmall = {
    fontSize: "11px",
    lineHeight: "16px",
    color: colors.fgHigh,
    fontWeight: 500,
}

const link = {
    cursor: "pointer",
}

const ellipsis = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: 240,
    whiteSpace: "nowrap",
}

export const publicTheme = {
    name: "publicTheme",
    textSmall,
    textMedium,
    textLarge,
    textXSmall,
    link,
    ellipsis,
    colors,
}

const colorsDark = {
    bgPrimary: "#202023",
    bgSecondary: "#1C1C1F",
    bgElevated: "#252528",
    brandLow: "#232537",
    brandBase: "#6666f3",
    brandHigh: "#7F9EF5",
    onBrand: "hsl(0 0% 0%)",
    fg100: "hsl(0 0% 100% / 0.04)",
    fg100Solid: "hsl(0 0% 4%)",
    fg200: "hsl(0 0% 100% / 0.08)",
    fg200Solid: "hsl(0 0% 8%)",
    fg300: "hsl(0 0% 100% / 0.12)",
    fg300Solid: "hsl(0 0% 12%)",
    fg400: "hsl(0 0% 100% / 0.18)",
    fg400Solid: "hsl(0 0% 18%)",
    fg500: "hsl(0 0% 100% / 0.24)",
    fg500Solid: "hsl(0 0% 24%)",
    fgLow: "hsl(0 0% 100% / 0.38)",
    fgLowSolid: "hsl(0 0% 38%)",
    fgMedium: "hsl(0 0% 100% / 0.6)",
    fgMediumSolid: "hsl(0 0% 60%)",
    fgHigh: "hsl(0 0% 100% / 0.87)",
    fgHighSolid: "hsl(0 0% 87%)",
    fgColor: "hsl(0 0% 0%)",
}

export const publicThemeDark = {
    name: "publicThemeDark",
    textSmall,
    textMedium,
    textLarge,
    textXSmall,
    link,
    ellipsis,
    colors: colorsDark,
}
