import * as React from "react"
function SvgWarning(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.821 13.016L9.537 5.952a2.504 2.504 0 114.926 0l-1.284 7.064a1.198 1.198 0 01-2.358 0z"
                fill="#F04858"
            />
            <circle cx={12} cy={19} r={2} fill="#F04858" />
        </svg>
    )
}
export default SvgWarning
