import styled from "@emotion/styled"
import { analytics } from "@shiftx/analytics"
import { X } from "@shiftx/icons"
import {
    AnimatePresence,
    motion,
    useMotionTemplate,
    useScroll,
    useTransform,
} from "framer-motion"
import Link from "next/link"
import { useRouter } from "next/router"
import { Fragment, useEffect, useState } from "react"
import { Chevron } from "../icons/Chevron"
import { ShiftX } from "../logos/shiftx"
import { RichText } from "../routes/Case"
import { greaterThan, lessThan } from "../utils/breakpoints"
import { useIsValidSession } from "../utils/useIsvalidSession"
import { FadedDividerVertical } from "./Divider"
import { LinkButton } from "./LinkButton"
import { MenuIcon } from "./MenuIcon"
import { Section } from "./Section"
import { SmallScreenMenu } from "./SmallScreenMenu"
import { Paragraph } from "./Text"
import { Title5 } from "./Text"
import { Image } from "../components/Image"
import { ButtonContainer } from "./Button"
import { css } from "@shiftx/styles"

const Container = styled(Section)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Menu = styled("ul")`
    display: flex;
    gap: 48px;
    margin: 0;
    list-style: none;
    margin-left: 40px;
    font-size: 14px;
    line-height: 20px;

    ${lessThan(1)`display: none;`};

    li:hover {
        color: var(--brand-base);
    }

    li:hover > div:nth-of-type(1) {
        svg {
            transform: rotate(180deg);
        }
    }
`

const HeaderDropdown = ({ children }) => {
    return (
        <motion.div
            key="dropdown"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
            css={css`
                position: absolute;
                height: 260px;
                top: 42px;
                left: 0;
                right: 0;
                z-index: 9999;
                display: flex;
                justify-content: center;
            `}
        >
            <ul
                css={css`
                    display: flex;
                    justify-content: space-evenly;
                    margin: 30px 24px 0 24px;
                    height: 100%;
                    padding: 24px;
                    max-width: 1120px;
                    width: 1120px;
                    border-radius: 12px;
                    background: var(--bg-primary);
                    box-shadow: 0 0 20px var(--fg-200);
                `}
            >
                {children}
            </ul>
        </motion.div>
    )
}

const HeaderDropdownItem = ({ item }) => {
    return (
        <li
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                list-style: none;
                padding: 0;
                margin: 0;
                width: 256px;
                height: 260px;
                max-height: 260px;
                transition: transform 0.3s ease;
                :hover {
                    transform: scale(1.02);
                }
            `}
        >
            <Link
                href={`/use-case/${item.slug.current}`}
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: inherit;
                    width: inherit;
                    -webkit-font-smoothing: antialiased;
                `}
            >
                <div
                    css={css`
                        flex: 1;
                        height: fit-content;
                        width: fit-content;
                    `}
                >
                    <Image
                        key={item.title}
                        image={item?.thumb?.[0]}
                        alt={item?.thumb?.[0]?.alt}
                        css={css`
                            width: 100%;
                            height: 100%;
                        `}
                    />
                </div>
                <Title5
                    css={css`
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        margin-top: 16px;
                    `}
                >
                    {item.title}
                </Title5>
                <Paragraph
                    css={css`
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 500;
                        text-wrap: balance;
                        color: var(--fg-low);
                    `}
                >
                    {item.short}
                </Paragraph>
            </Link>
        </li>
    )
}

const DropDownMenuNew = ({ subMenu }) => {
    return (
        <HeaderDropdown>
            {subMenu.map((subItem, index) => {
                if (index >= 3) {
                    return null
                } else {
                    return (
                        <Fragment key={index}>
                            <HeaderDropdownItem item={subItem} />
                            {2 !== index && <FadedDividerVertical />}
                        </Fragment>
                    )
                }
            })}
        </HeaderDropdown>
    )
}

export const Header = ({
    isCampaign = false,
    preHeader,
    pages = [],
    preview = false,
}) => {
    const router = useRouter()
    const isLoggedIn = useIsValidSession()
    const [showMenu, setShowMenu] = useState(null)
    const [showFullScreenMenu, setShowFullScreenMenu] = useState(false)
    const { scrollYProgress } = useScroll()
    const scrollOpacity = useTransform(
        scrollYProgress,
        [0, 0.05, 1],
        [0, 0.87, 0.87]
    )
    const backgroundColor = useMotionTemplate`hsla(0, 0%, 100%, ${scrollOpacity})`

    useEffect(() => {
        if (showFullScreenMenu) {
            document.body.classList.add("noscroll")
        } else {
            if (document.body.classList.contains("noscroll")) {
                document.body.classList.remove("noscroll")
            }
        }

        return () => {
            if (document.body.classList.contains("noscroll")) {
                document.body.classList.remove("noscroll")
            }
        }
    }, [showFullScreenMenu])

    if (router?.events) {
        router.events.on("routeChangeComplete", () => {
            setShowFullScreenMenu(false)
            setShowMenu(null)
        })
    }

    return (
        <div
            css={css`
                background-color: ${router.pathname === "/ai"
                    ? "transparent"
                    : "#f5f5f5"};
            `}
        >
            {showFullScreenMenu && <SmallScreenMenu pages={pages} />}

            {preview && (
                <div
                    css={css`
                        background-color: #000;
                        padding: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 12px;
                    `}
                >
                    Preview.
                </div>
            )}

            {!isCampaign && preHeader?.show && (
                <Link href={preHeader.link}>
                    <div
                        css={css`
                            background-color: var(--brand-base);
                            p {
                                margin: 0;
                            }
                            padding: 8px 16px;
                            font-size: 14px;
                            color: #fff;
                            text-align: center;
                            ${preHeader.link &&
                            css`
                                cursor: pointer;
                            `}
                        `}
                    >
                        <RichText content={preHeader.content} />
                    </div>
                </Link>
            )}

            <motion.header
                css={css`
                    padding: 16px 0;
                    position: sticky;
                    top: 0;
                    z-index: 100;
                    backdrop-filter: blur(20px);
                    ${lessThan(0)`padding: 8px 0;`}
                `}
            >
                <Container>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Link href="/" aria-label="Go to homepage">
                            <div
                                css={css`
                                    color: var(--fg-high);
                                `}
                            >
                                <ShiftX />
                            </div>
                        </Link>

                        <Menu showMenu={showMenu}>
                            {!isCampaign &&
                                pages
                                    .filter(item => !item.hide)
                                    .map((item, index) => {
                                        if (
                                            item.subMenu &&
                                            item.subMenu.length > 0
                                        ) {
                                            const isActive = item.subMenu.some(
                                                item => {
                                                    return router.asPath.includes(
                                                        item.url
                                                    )
                                                }
                                            )
                                            return (
                                                <li
                                                    key={index}
                                                    css={css`
                                                        ${isActive &&
                                                        `color:blue`};
                                                    `}
                                                    onMouseOver={() => {
                                                        setShowMenu(index)
                                                    }}
                                                    onMouseOut={() => {
                                                        setShowMenu(null)
                                                    }}
                                                >
                                                    <div
                                                        tabIndex={0}
                                                        css={css`
                                                            display: flex;
                                                            align-items: center;
                                                            cursor: pointer;
                                                        `}
                                                        onClick={() => {
                                                            setShowMenu(
                                                                state =>
                                                                    state
                                                                        ? null
                                                                        : index
                                                            )
                                                        }}
                                                    >
                                                        {item.title}{" "}
                                                        <Chevron
                                                            css={css`
                                                                margin: 2px 0 0
                                                                    4px;
                                                            `}
                                                        />
                                                    </div>
                                                    <AnimatePresence>
                                                        {showMenu === index &&
                                                            item.subMenu && (
                                                                <DropDownMenuNew
                                                                    subMenu={
                                                                        item.subMenu
                                                                    }
                                                                />
                                                            )}
                                                    </AnimatePresence>
                                                </li>
                                            )
                                        }

                                        return (
                                            <li
                                                key={index}
                                                css={css`
                                                    ${router.pathname.includes(
                                                        item.url
                                                    ) && `color:blue`}
                                                `}
                                            >
                                                {item.url && (
                                                    <Link href={item.url}>
                                                        {item.title}
                                                    </Link>
                                                )}

                                                {!item.url && item.title}
                                            </li>
                                        )
                                    })}
                        </Menu>
                    </div>

                    <ButtonContainer
                        aria-label="Menu"
                        onClick={() => {
                            setShowFullScreenMenu(state => !state)
                        }}
                        css={css`
                            padding: 0 6px;
                            ${greaterThan(1)`display: none;`};
                            color: var(--fg-high);
                        `}
                    >
                        {showFullScreenMenu ? <X /> : <MenuIcon />}
                    </ButtonContainer>

                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            ${lessThan(1)`display: none;`};
                        `}
                    >
                        {!isCampaign && !isLoggedIn && (
                            <LinkButton
                                href="/login"
                                shape="pill"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                Log in
                            </LinkButton>
                        )}

                        {(!isLoggedIn || isCampaign) && (
                            <LinkButton
                                href="/signup"
                                variant="primary"
                                shape="pill"
                                onClick={() => {
                                    analytics.track("Get started", {
                                        source: "header",
                                    })
                                }}
                            >
                                Sign up
                            </LinkButton>
                        )}

                        {isLoggedIn && !isCampaign && (
                            <LinkButton
                                href="/dashboard"
                                variant="primary"
                                shape="pill"
                            >
                                Dashboard
                            </LinkButton>
                        )}
                    </div>
                </Container>
            </motion.header>
        </div>
    )
}
