import * as React from "react"
function SvgChat(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5 9.2V20l3.797-2.531a1.6 1.6 0 01.887-.269H15.8A3.2 3.2 0 0019 14V9.2A3.2 3.2 0 0015.8 6H8.2A3.2 3.2 0 005 9.2z"
                stroke="currentColor"
                strokeOpacity={0.87}
                strokeWidth={2}
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default SvgChat
