import * as React from "react"
function SvgLightbulb(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 2a1 1 0 11-2 0 1 1 0 012 0zm-3 19a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm8.01-15.51a1 1 0 100-2 1 1 0 000 2zM6.99 16.51a1 1 0 11-2 0 1 1 0 012 0zM20.5 11.5a1 1 0 100-2 1 1 0 000 2zm-16-1a1 1 0 11-2 0 1 1 0 012 0zm13.51 7.01a1 1 0 100-2 1 1 0 000 2zM6.99 4.49a1 1 0 11-2 0 1 1 0 012 0zM8 11a4 4 0 116.116 3.395 1.53 1.53 0 00-.703 1.043L13.153 17h-2.306l-.26-1.562a1.53 1.53 0 00-.703-1.043A3.997 3.997 0 018 11zm4-6a6 6 0 00-3.35 10.978l.294 1.769A1.5 1.5 0 0010.424 19h3.152a1.5 1.5 0 001.48-1.253l.295-1.77A6 6 0 0012 5z"
                fill="currentColor"
                fillOpacity={0.87}
            />
        </svg>
    )
}
export default SvgLightbulb
