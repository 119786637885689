import { Spinner as Icon } from "@shiftx/icons"
import { css, keyframes, styled } from "@shiftx/styles"
import React from "react"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinnerAnimation = styled.div`
    animation: ${rotate} 1s linear infinite;
    display: inline-block;
    will-change: transform;
`

export const Spinner = ({ size = 20, ...props }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
            `}
            {...props}
        >
            <SpinnerAnimation>
                <Icon width={size} height={size} />
            </SpinnerAnimation>
        </div>
    )
}
