import { analytics } from "@shiftx/analytics"
import Link from "next/link"
import { Fragment, useState } from "react"
import { Chevron } from "../icons/Chevron"
import { greaterThan } from "../utils/breakpoints"
import { useIsValidSession } from "../utils/useIsvalidSession"
import { LinkButton } from "./LinkButton"
import { css, styled } from "@shiftx/styles"

const FullScreenMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 60px;

    a:active,
    a:focus {
        color: var(--brand-base);
    }

    ul {
        border: 1px solid var(--fg-200);
        border-radius: 6px;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 16px;

        a li {
            display: block;
            padding: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            :active,
            :focus {
                color: var(--brand-base);
            }
        }

        a:not(:last-child) li {
            border-bottom: 1px solid var(--fg-200);
        }
    }

    & > li {
        margin-top: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--fg-high);
    }
`

export const SmallScreenMenu = ({ pages = [] }) => {
    const isLoggedIn = useIsValidSession()
    const [openCollection, setOpenCollection] = useState(null)

    return (
        <div
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: var(--bg-primary);
                overflow-y: auto;
                padding: 32px 24px;
                z-index: 100;
                height: 100vh;
            `}
        >
            <FullScreenMenu>
                {pages
                    .filter(item => !item.hide)
                    .map((item, index) => {
                        if (item.subMenu && item.subMenu.length > 0) {
                            return (
                                <li key={index}>
                                    <div
                                        onClick={() => {
                                            if (openCollection === item._key) {
                                                setOpenCollection(null)
                                            } else {
                                                setOpenCollection(item._key)
                                            }
                                        }}
                                        css={css`
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;
                                            ${openCollection === item._key &&
                                            css`
                                                color: var(--brand-base);
                                            `}
                                        `}
                                    >
                                        {item.title}
                                        <div
                                            css={css`
                                                margin: 4px 0px 0px 4px;
                                            `}
                                        >
                                            <Chevron
                                                width={20}
                                                height={20}
                                                css={css`
                                                    transition: transform 0.2s
                                                        ease-in-out;
                                                    transform: rotate(
                                                        ${openCollection ===
                                                        item._key
                                                            ? "180deg"
                                                            : "0deg"}
                                                    );
                                                `}
                                            />
                                        </div>
                                    </div>

                                    {openCollection === item._key && (
                                        <div>
                                            <ul>
                                                {item.subMenu
                                                    .filter(
                                                        dropdownItem =>
                                                            !dropdownItem.hide ||
                                                            dropdownItem.url
                                                    )
                                                    .map(
                                                        (
                                                            dropdownItem,
                                                            index2
                                                        ) => {
                                                            return (
                                                                <Link
                                                                    key={index2}
                                                                    href={`/use-case/${dropdownItem.slug.current}`}
                                                                >
                                                                    <li>
                                                                        <div>
                                                                            {
                                                                                dropdownItem.title
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                dropdownItem.subTitle
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                </Link>
                                                            )
                                                        }
                                                    )}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            )
                        }

                        return (
                            <li key={index}>
                                {item.url && (
                                    <Link href={item.url}>{item.title}</Link>
                                )}

                                {!item.url && item.title}
                            </li>
                        )
                    })}
            </FullScreenMenu>

            <div
                css={css`
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    ${greaterThan(1)`display: none;`};
                `}
            >
                <div
                    css={theme => css`
                        border-top: 1px solid;
                    `}
                />

                {!isLoggedIn && (
                    <Fragment>
                        <LinkButton
                            href="/login"
                            shape="pill"
                            css={css`
                                margin-top: 24px;
                                width: fit-content;
                            `}
                        >
                            Log in
                        </LinkButton>

                        <LinkButton
                            href="/signup/?plan=teamTrial"
                            variant="primary"
                            shape="pill"
                            onClick={() => {
                                analytics.track("Get started", {
                                    source: "header",
                                })
                            }}
                            css={css`
                                margin-top: 16px;
                                width: fit-content;
                            `}
                        >
                            Sign up
                        </LinkButton>
                    </Fragment>
                )}

                {isLoggedIn && (
                    <LinkButton
                        href="/dashboard"
                        variant="primary"
                        shape="pill"
                        css={css`
                            margin-top: 24px;
                        `}
                    >
                        Dashboard
                    </LinkButton>
                )}
            </div>
        </div>
    )
}
