import * as React from "react"
function SvgDiamond(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4 12l8-8 8 8-8 8-8-8z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinejoin="round"
            />
            <circle cx={12} cy={12} r={1} fill="currentColor" />
        </svg>
    )
}
export default SvgDiamond
