import * as React from "react"
function SvgSpinner(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12a8 8 0 00-8-8V2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2a8 8 0 1016 0z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgSpinner
