import { PortableText } from "@portabletext/react"
import { imageBuilder } from "../../../config/sanity"
import { Asset } from "../../components/Asset"
import { BodyText } from "../../components/BodyText"
import { Main } from "../../components/Main"
import { Section } from "../../components/Section"
import { SeeForYourself } from "../../components/SeeForYourself"
import { serializers } from "../../components/Serializers"
import { Title2 } from "../../components/Text"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { CustomerCasePreview } from "../CustomerPage/CasePreview"
import { css } from "@shiftx/styles"

const Values = ({ content }) => {
    const companyLogoImageUrl = content?.logo
        ? imageBuilder.image(content.logo)?.url()
        : null

    return (
        <div
            css={css`
                padding: 32px;
                align-self: flex-start;
                border: 1px solid #eee;
                position: sticky;
                top: 24px;
                align-self: flex-start;

                ${lessThan(1)`
                    margin-bottom: 40px;
                    position: static;
                `}
            `}
        >
            <img
                src={companyLogoImageUrl}
                css={css`
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 32px;
                `}
                width="94"
            />
            <hr
                css={css`
                    border: none;
                    border-bottom: 1px solid var(--fg-200);
                `}
            />
            <div
                css={css`
                    font-family: "Source Code Pro", monospace;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: var(--fg-medium);
                    margin-top: 32px;
                `}
            >
                {content.company?.map((result, index) => {
                    return (
                        <div
                            key={index}
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 8px;
                            `}
                        >
                            <div>{result.label}</div>
                            <div
                                css={css`
                                    color: var(--fg-high);
                                `}
                            >
                                {result.value}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export const RichText = ({ content, ...props }) => {
    return (
        <div
            css={css`
                img {
                    width: 100%;
                }

                figure {
                    margin: 0;
                }

                ul {
                    list-style: none;
                    margin: 24px 0;
                    padding: 0;
                }

                a {
                    color: var(--brand-base);
                }

                li::before {
                    content: "•";
                    color: var(--brand-base);
                    display: inline-block;
                    width: 1.5rem;
                    font-size: 20px;
                }

                li {
                    margin-bottom: 16px;
                }
            `}
            {...props}
        >
            <PortableText value={content} components={serializers} />
        </div>
    )
}

const Quote = ({ content }) => {
    const quoteImageUrl = content?.quoteImage
        ? imageBuilder.image(content?.quoteImage)?.url()
        : null

    return (
        <div
            css={css`
                background-color: var(--fg-100);
                padding-top: var(--section-margin-inside);
                padding-bottom: var(--section-margin-inside);
                margin-top: 80px;
            `}
        >
            <Section
                css={css`
                    ${greaterThan(0)`  display: grid;
                    grid-template-columns: 4fr 8fr;`}
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        overflow: hidden;
                        width: 160px;
                        height: 160px;
                        margin: 0 auto;
                        /*  margin-bottom: 16px; */
                    `}
                >
                    <img src={quoteImageUrl} width="160" />
                </div>

                <div
                    css={css`
                        ${lessThan(0)`text-align:center`}
                        display: grid;
                        align-items: center;
                    `}
                >
                    <div>
                        <cite
                            css={css`
                                font-style: normal;
                                font-weight: 500;
                            `}
                        >
                            {content.quoteName}
                            <div
                                css={css`
                                    font-size: 14px;
                                    /*   color: #aaa; */
                                `}
                            >
                                {content.quoteTitle}
                            </div>
                        </cite>

                        <blockquote
                            css={css`
                                ${greaterThan(0)`
                                margin-right: 80px;
                            `}

                                margin: 0;
                                margin-top: 24px;
                                font-style: italic;
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 40px;
                            `}
                        >
                            “{content.quote}”
                        </blockquote>
                    </div>
                </div>
            </Section>
        </div>
    )
}

export const CasePage = ({ content, cases = [] }) => {
    return (
        <div>
            <Main
                css={css`
                    margin-bottom: 0;
                `}
            >
                <Section
                    css={css`
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fit,
                            minmax(20rem, 1fr)
                        );
                        grid-gap: var(--grid-gap);
                        align-items: center;
                    `}
                >
                    <div>
                        <Title2>{content.title}</Title2>

                        <div
                            css={css`
                                font-size: 18px;
                                line-height: 32px;
                                margin-top: 24px;
                            `}
                        >
                            {content.lead}
                        </div>

                        {/*  <Quote content={content.quote} /> */}
                    </div>

                    <div
                        css={css`
                            background: var(--fg-100);
                            border-radius: 24px;
                            overflow: hidden;
                        `}
                    >
                        {content.asset?.[0] ? (
                            <Asset asset={content.asset[0]} />
                        ) : (
                            content.headerImage && (
                                <Asset asset={content.headerImage} />
                            )
                        )}
                    </div>
                </Section>
                <Quote content={content.quote} />
                <Section
                    css={css`
                        margin-top: 80px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 12px;
                            ${lessThan(0)`
                                display: block; 
                            `}
                        `}
                    >
                        {content.results2?.map((result, index) => {
                            return (
                                <div
                                    css={css`
                                        background-color: var(--brand-low);
                                        padding: 32px;
                                        border-radius: 12px;
                                        flex-grow: 1;
                                        flex-basis: 0;
                                        font-size: 14px;
                                        line-height: 24px;
                                        ${lessThan(0)`
                                          margin-bottom: 1rem; 
                                        `}
                                    `}
                                    key={index}
                                >
                                    <div
                                        css={css`
                                            flex-grow: 1;
                                            flex-basis: 0;
                                            font-weight: 700;
                                            font-size: 16px;
                                            line-height: 32px;
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        {result.label}
                                    </div>
                                    <div>{result.value}</div>
                                </div>
                            )
                        })}
                    </div>

                    <div
                        css={css`
                            display: grid;
                            margin-top: 80px;
                            ${greaterThan(1)`
                            grid-template-columns: minmax(auto, 24rem) minmax(
                                    auto,
                                    40rem
                                );
                            grid-gap: var(--grid-gap);
                            `}
                        `}
                    >
                        <Values content={content} />
                        <div css={css``}>
                            <BodyText content={content.description} />
                        </div>
                    </div>
                </Section>

                <SeeForYourself />
                <Section
                    css={css`
                        margin-top: var(--section-margin);
                    `}
                >
                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: repeat(
                                auto-fit,
                                minmax(20rem, 1fr)
                            );
                            gap: 2rem;
                            padding-bottom: 50px;
                        `}
                    >
                        {cases.map((singleCase, index) => {
                            return (
                                <CustomerCasePreview
                                    key={index}
                                    content={singleCase}
                                />
                            )
                        })}
                    </div>
                </Section>
            </Main>
        </div>
    )
}
