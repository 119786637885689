import * as React from "react"
function SvgModel(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 12.577L5.34 9.31v6.536L11 19.113v-6.536zm2 6.536l5.66-3.268V9.31L13 12.577v6.536zm4.66-11.536L12 4.31 6.34 7.577 12 10.845l5.66-3.268zm-4.66-5a2 2 0 00-2 0L4.34 6.423a2 2 0 00-1 1.732v7.69a2 2 0 001 1.732L11 21.423a2 2 0 002 0l6.66-3.846a2 2 0 001-1.732v-7.69a2 2 0 00-1-1.732L13 2.577z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgModel
