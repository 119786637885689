import * as React from "react"
function SvgStep(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx={12}
                cy={12}
                r={5.25}
                stroke="#484A5B"
                strokeWidth={1.5}
            />
        </svg>
    )
}
export default SvgStep
