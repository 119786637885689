import * as React from "react"
function SvgTag(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13 20l-9-9V6a2 2 0 012-2h5l9 9-7 7z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinejoin="round"
            />
            <path
                d="M9.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgTag
