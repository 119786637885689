import * as React from "react"
function SvgCloseCircle(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx={12}
                cy={12}
                r={8}
                fill="currentColor"
                fillOpacity={0.06}
            />
            <path
                d="M9 9l3 3 3 3m0-6l-6 6"
                stroke="currentColor"
                strokeOpacity={0.6}
                strokeWidth={1.5}
            />
        </svg>
    )
}
export default SvgCloseCircle
