import * as React from "react"
function SvgBold(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.862 19h5.888c3.331 0 4.965-1.697 4.965-3.963 0-2.202-1.563-3.494-3.111-3.573v-.142c1.42-.333 2.543-1.328 2.543-3.096 0-2.166-1.563-3.771-4.716-3.771H6.862V19zm2.635-2.202v-4.261h2.905c1.627 0 2.635.994 2.635 2.294 0 1.158-.795 1.967-2.706 1.967H9.497zm0-6.157V6.628h2.664c1.548 0 2.35.817 2.35 1.939 0 1.278-1.036 2.074-2.407 2.074H9.497z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgBold
