export enum ActorCategoryId {
    CustomerContext = "customercontext",
    Customer = "customer",
    Reseller = "reseller",
    Company = "company",
    System = "system",
    Supplier = "supplier",
    Competitor = "competitor",
    Government = "government",
    Custom = "custom",
}
