import * as React from "react"
function SvgArrowDiagonalUp(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 18L18 6m0 0H8.118M18 6v9.882"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgArrowDiagonalUp
