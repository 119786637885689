import * as React from "react"
function SvgParent(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.668 6.668l4-4 4 4"
                stroke="currentColor"
                strokeWidth={1.333}
            />
            <path
                d="M6.668 3.332v6.667a2.667 2.667 0 002.667 2.666H12"
                stroke="currentColor"
                strokeWidth={1.333}
            />
        </svg>
    )
}
export default SvgParent
