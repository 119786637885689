import * as React from "react"
function SvgTrash(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4a.5.5 0 00-.5.5V5h1v-.5A.5.5 0 0012 4zm2.5 1v-.5a2.5 2.5 0 00-5 0V5H4v2h16V5h-5.5zM7 9v10.667c0 .184.15.333.333.333h9.334c.184 0 .333-.15.333-.333V9h2v10.667A2.333 2.333 0 0116.667 22H7.333A2.333 2.333 0 015 19.667V9h2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgTrash
