import * as React from "react"
function SvgCursor(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.419 2.419l19.96 6.653-8.317 4.99-4.99 8.317L2.42 2.419zM5.58 5.58l4.013 12.04 3.01-5.016 5.017-3.01L5.581 5.58z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgCursor
