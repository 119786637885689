import { interfaceColors } from "@shiftx/data"
import { IssueSeverity } from "@shiftx/types"

export const issueSeverityColor = (severity: IssueSeverity): string => {
    switch (severity) {
        case IssueSeverity.Trivial:
            return interfaceColors.gray[500]
        case IssueSeverity.Minor:
            return interfaceColors.green[500]
        case IssueSeverity.Major:
            return interfaceColors.yellow[500]
        case IssueSeverity.Critical:
            return interfaceColors.orange[500]
        case IssueSeverity.Blocker:
            return interfaceColors.red[500]
        default:
            return "#000000"
    }
}
