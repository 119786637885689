import * as React from "react"
function SvgFullscreen(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14 4h6m0 0v6m0-6l-6 6M10 20H4m0 0v-6m0 6l6-6"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgFullscreen
