import * as React from "react"
function SvgAzureAdLogo(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M24 0H0v24h24V0z" fill="#fff" />
            <path d="M11.535.93H.93v10.605h10.606V.93z" fill="#F35325" />
            <path d="M23.07.93H12.466v10.605H23.07V.93z" fill="#81BC06" />
            <path d="M11.535 12.465H.93V23.07h10.606V12.465z" fill="#05A6F0" />
            <path d="M23.07 12.465H12.466V23.07H23.07V12.465z" fill="#FFBA08" />
        </svg>
    )
}
export default SvgAzureAdLogo
