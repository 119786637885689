import * as React from "react"
function SvgMention(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.667 10.667S14 9.333 12 9.333c-2 0-2.667 1.334-2.667 2.667 0 1.333.667 2.667 2.667 2.667 2 0 2.667-2 2.667-3.334"
                stroke="currentColor"
                strokeWidth={2}
            />
            <path
                d="M14.667 8.667V12c0 2.667 1.524 3.333 2.666 3.333C18.476 15.333 20 14.667 20 12a8 8 0 10-4 6.93"
                stroke="currentColor"
                strokeWidth={2}
            />
        </svg>
    )
}
export default SvgMention
