export enum RefKind {
    Actor = "Actor",
    Channel = "Channel",
    Comment = "Comment",
    Confirmation = "Confirmation",
    ContentBlock = "ContentBlock",
    Customer = "Customer",
    CustomerBilling = "CustomerBilling",
    Favorite = "Favorite",
    File = "File",
    Invite = "Invite",
    IP = "IP",
    Issue = "Issue",
    Login = "Login",
    Membership = "Membership",
    Mutation = "Mutation",
    PasswordResetToken = "PasswordResetToken",
    Process = "Process",
    ProcessItem = "ProcessItem",
    PublicLink = "PublicLink",
    Relation = "Relation",
    Run = "Run",
    Session = "Session",
    SSOConfiguration = "SSOConfiguration",
    SSOUser = "SSOUser",
    Tag = "Tag",
    Task = "Task",
    User = "User",
    Variant = "ProcessVariant",
    Version = "ProcessVersion",
}
