import * as React from "react"
function SvgShow(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.467 12.57c-.136-.22-.248-.413-.335-.57A16.684 16.684 0 016.14 9.162C7.618 7.49 9.617 6 12 6c2.383 0 4.382 1.49 5.86 3.162A16.69 16.69 0 0119.868 12a16.69 16.69 0 01-2.008 2.838C16.382 16.51 14.383 18 12 18c-2.383 0-4.382-1.49-5.86-3.162a16.684 16.684 0 01-1.673-2.269zM21.9 11.564l.21.435-.21.436-.001.002-.003.006-.01.02-.034.068c-.03.058-.072.14-.128.244a18.695 18.695 0 01-2.366 3.387C17.744 17.99 15.243 20 12 20s-5.743-2.01-7.359-3.838a18.69 18.69 0 01-2.493-3.63 7.744 7.744 0 01-.034-.068l-.01-.02-.003-.006-.002-.004L1.89 12l.21-.436.002-.002.003-.006.01-.02a7.744 7.744 0 01.162-.312A18.689 18.689 0 014.64 7.837C6.257 6.01 8.758 4 12 4s5.743 2.01 7.359 3.838a18.693 18.693 0 012.365 3.386 11.522 11.522 0 01.162.312l.01.02.003.006.001.003zM10 12a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgShow
