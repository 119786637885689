import * as React from "react"
function SvgExclamationMark(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2 11.35c.14-1.832.3-3.948.3-7.35h-3c0 3.402.16 5.518.3 7.35.106 1.4.2 2.634.2 4.15h2c0-1.515.094-2.75.2-4.15zM12 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgExclamationMark
