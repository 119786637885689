import * as React from "react"
function SvgExclamation(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={12} fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.467 11.567c.092-1.222.2-2.632.2-4.9h-2c0 2.268.107 3.678.2 4.9.07.933.133 1.756.133 2.766h1.333c0-1.01.063-1.833.134-2.766zm-.8 6.433a1 1 0 100-2 1 1 0 000 2z"
                fill="#fff"
            />
        </svg>
    )
}
export default SvgExclamation
