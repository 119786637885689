import * as React from "react"
function SvgDottedArrow(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.586 11l-5.793-5.793 1.414-1.414L21.414 12l-8.207 8.207-1.414-1.414L17.586 13H13v-2h4.586zM6 13H3v-2h3v2zm5 0H8v-2h3v2z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgDottedArrow
