import * as React from "react"
function SvgError(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={8} stroke="#F04858" strokeWidth={1.5} />
            <path
                d="M10.747 8.48a1.27 1.27 0 112.506 0l-.67 4.027a.59.59 0 01-1.165 0l-.671-4.027z"
                fill="#F04858"
            />
            <circle cx={12} cy={15} r={1} fill="#F04858" />
        </svg>
    )
}
export default SvgError
