import { isRunningInBrowser } from "@shiftx/utils"
import { useEffect, useState } from "react"

const getFromStorage = (key, session = false) => {
    const storage = session ? sessionStorage : localStorage
    return JSON.parse(storage.getItem(key))
}

const getCurrentSession = () => {
    const currentSessionRef = getFromStorage("sx:currentSessionScopeRef", true)
    if (currentSessionRef) {
        return getFromStorage(`sx:session:${currentSessionRef}`)
    }

    const lastSessionRef = getFromStorage("sx:lastActivatedSessionScopeRef")
    if (lastSessionRef) {
        return getFromStorage(`sx:session:${lastSessionRef}`)
    }
    return null
}

export const useIsValidSession = () => {
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        const session = getCurrentSession()

        if (session) {
            const { expiresAt } = session
            if (expiresAt) {
                setIsValid(Date.parse(expiresAt) > Date.now())
            }
        }
    }, [])

    return isValid
}

export const useGetSession = () => {
    const isClient = isRunningInBrowser()
    const isValid = useIsValidSession()

    if (isClient && isValid) {
        return getCurrentSession()
    }

    return null
}
