import * as React from "react"

export const Chevron = props => {
    return (
        <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.146 4.354l.708-.708L6 6.793l3.146-3.147.708.708L6 8.207 2.146 4.354z"
                fill="currentColor"
            />
        </svg>
    )
}
