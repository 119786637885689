import { Spinner } from "@shiftx/components"
import { css, getThemeProp, styled, TextStyle } from "@shiftx/styles"
import React from "react"

const BaseButton = styled("button")`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    appearance: none;
    text-decoration: none;
    text-align: center;
    background-color: transparent;
    border: none;
    color: var(--fg-high);
    position: relative;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    height: 36px;
    padding: 0 12px;
    -webkit-font-smoothing: antialiased;

    ${props =>
        !props.disabled &&
        css`
            cursor: pointer;
        `}
    :active,
    :focus {
        outline: none;
    }

    transition: box-shadow 0.2s;
    white-space: nowrap;
    vertical-align: middle;

    ${props =>
        props.showDot &&
        css`
            :after {
                content: "";
                position: absolute;
                right: 8px;
                top: -7px;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                border-radius: 50%;
                background-color: var(--brand-base);
            }
        `}
`

export enum ButtonType {
    Primary = "primary",
    Secondary = "secondary",
    Red = "red",
    Green = "green",
}

const PrimaryButton = styled(BaseButton)`
    background-color: var(--brand-base);
    color: #ffffff;

    :hover {
        background-color: var(--brand-base);
    }

    :active,
    :focus {
        background-color: var(--brand-base);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }
`
const SecondaryButton = styled(BaseButton)`
    background-color: var(--bg-primary);
    border: 1px solid var(--fg-300);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                border-color: var(--fg-400);
            }

            :active,
            :focus {
                border-color: var(--fg-400);
                box-shadow: 0px 0px 0px 4px var(--fg-200);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--fg-low);
        `}
`
const RedButton = styled(BaseButton)`
    background-color: var(--red-500);
    color: #fff;

    :hover {
        background-color: var(--red-700);
    }

    :active,
    :focus {
        border-color: var(--fg-400);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }

    ${props =>
        props.disabled &&
        css`
            background-color: var(--fg-300);
            :hover {
                background-color: var(--fg-300);
            }
        `}
`

const GreenButton = styled(BaseButton)`
    background-color: var(--green-500);
    color: #fff;

    :hover {
        background-color: var(--green-700);
    }

    :active,
    :focus {
        border-color: var(--fg-400);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }

    ${props =>
        props.disabled &&
        css`
            background-color: var(--fg-300);
            :hover {
                background-color: var(--fg-300);
            }
        `}
`

export interface ButtonProps {
    children: React.ComponentType | React.ReactNode
    variant?: ButtonType
    onClick?: () => void
    showDot?: boolean
    disabled?: boolean
}

const Button2 = ({
    children,
    variant = ButtonType.Secondary,
    ...props
}: ButtonProps) => {
    switch (variant) {
        case ButtonType.Primary:
            return <PrimaryButton {...props}>{children}</PrimaryButton>
        case ButtonType.Red:
            return <RedButton {...props}>{children}</RedButton>
        case ButtonType.Green:
            return <GreenButton {...props}>{children}</GreenButton>
        case ButtonType.Secondary:
            return <SecondaryButton {...props}>{children}</SecondaryButton>
    }
}

interface LoadingButtonProps {
    children: React.ComponentType | React.ReactNode
    variant?: ButtonType
    isLoading?: boolean
    spinnerColor?: string
    showDot?: boolean
}

export const LoadingButton = ({
    variant = ButtonType.Secondary,
    isLoading = false,
    children,
    spinnerColor,
    showDot,
    ...rest
}: LoadingButtonProps) => {
    return (
        <Button
            variant={variant}
            disabled={isLoading}
            {...rest}
            showDot={isLoading ? false : showDot}
        >
            {isLoading && <Spinner />}
            {!isLoading && children}
        </Button>
    )
}

export const Button = ({ children, ...props }) => {
    return (
        <Button2
            css={css`
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                padding: 12px 32px;
                height: auto;
            `}
            {...props}
        >
            {children}
        </Button2>
    )
}

export const ButtonContainer = ({ children, ...rest }) => {
    return (
        <BaseButton
            css={css`
                padding: 0;
                :focus-visible {
                    box-shadow: 0px 0px 0px 4px var(--fg-200);
                    outline: 2px solid #015fcc;
                }
            `}
            {...rest}
        >
            {children}
        </BaseButton>
    )
}
