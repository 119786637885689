import * as React from "react"
function SvgBullets(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 7h12V5H9v2zm0 6h10v-2H9v2zm12 6H9v-2h12v2zM6 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-1.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgBullets
