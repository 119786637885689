import * as React from "react"
function SvgAddsplit(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.702 7.359a3.25 3.25 0 014.596 0l2.343 2.343a3.25 3.25 0 010 4.596l-2.343 2.343a3.25 3.25 0 01-4.596 0l-2.343-2.343a3.25 3.25 0 010-4.596l2.343-2.343z"
                stroke="#181920"
                strokeWidth={1.5}
            />
        </svg>
    )
}
export default SvgAddsplit
