import * as React from "react"
function SvgHide(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.707 20.707l16-16-1.414-1.414-2.357 2.357C15.576 4.705 13.916 4 12 4 8.758 4 6.257 6.01 4.641 7.838a18.688 18.688 0 00-2.493 3.63 7.744 7.744 0 00-.034.068l-.01.02-.003.006-.001.002-.21.436.21.434v.002l.002.004.004.009.016.031.056.111a18.666 18.666 0 001.025 1.708 17.336 17.336 0 002.31 2.774l-2.22 2.22 1.414 1.414zm9.325-12.153l1.462-1.462C14.45 6.428 13.281 6 12 6 9.617 6 7.618 7.49 6.14 9.162A16.685 16.685 0 004.132 12l.003.006c.163.29.406.7.726 1.173a15.3 15.3 0 002.067 2.48l1.626-1.627a4 4 0 015.478-5.478zm-1.514 1.514a2 2 0 00-2.45 2.45l2.45-2.45zM12 20c-1.362 0-2.594-.355-3.684-.902l1.515-1.514c.68.263 1.405.416 2.169.416 2.383 0 4.382-1.49 5.86-3.162A16.69 16.69 0 0019.868 12a16.862 16.862 0 00-1.826-2.628l1.418-1.418a18.775 18.775 0 012.264 3.27 11.522 11.522 0 01.313.624l.073.152-.072.151a16.122 16.122 0 01-.314.625 18.695 18.695 0 01-2.366 3.387C17.744 17.99 15.243 20 12 20z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgHide
