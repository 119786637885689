import * as React from "react"
function SvgCog(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.257 2.718l-.15 1.359-.401.136c-.497.17-.95.435-1.337.774l-.319.278-1.254-.55a5.387 5.387 0 00-.742 1.286l1.101.81-.08.415a4.024 4.024 0 000 1.547l.08.415-1.101.81a5.387 5.387 0 00.742 1.286l1.254-.55.319.279c.387.339.84.604 1.337.773l.4.137.151 1.359a5.372 5.372 0 001.485 0l.15-1.36.401-.136c.497-.17.95-.434 1.338-.773l.318-.279 1.254.55a5.38 5.38 0 00.742-1.286l-1.101-.81.081-.415a4.028 4.028 0 000-1.547l-.081-.414 1.101-.81a5.373 5.373 0 00-.742-1.286l-1.254.549-.319-.278a3.995 3.995 0 00-1.337-.774l-.4-.136-.151-1.36a5.386 5.386 0 00-1.485 0zM6.5 1.503a6.685 6.685 0 013 0l.46.106.167 1.499a5.33 5.33 0 011.045.604l1.382-.606.322.346a6.685 6.685 0 011.5 2.598l.139.452-1.216.894a5.371 5.371 0 010 1.207l1.216.894-.139.452a6.682 6.682 0 01-1.5 2.598l-.322.346-1.382-.606a5.324 5.324 0 01-1.045.605L9.96 14.39l-.46.106a6.686 6.686 0 01-3 0l-.461-.106-.167-1.5a5.328 5.328 0 01-1.044-.604l-1.383.606-.322-.346a6.684 6.684 0 01-1.5-2.598l-.139-.452L2.7 8.603a5.382 5.382 0 010-1.207l-1.216-.894.14-.452a6.686 6.686 0 011.499-2.598l.322-.346 1.383.606a5.329 5.329 0 011.044-.604l.167-1.5.46-.105zM8 6.666a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM5.333 8a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgCog
