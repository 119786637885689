import * as React from "react"
function SvgSketch(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.282 6.22c-.508.324-1.074 1.06-1.297 2.344l-1.97-.342C4.31 6.518 5.12 5.225 6.209 4.533c1.102-.7 2.473-.747 3.548.08a2.824 2.824 0 011.075 1.69c.134.646.05 1.31-.145 1.938-.384 1.24-1.266 2.56-2.34 3.796-.973 1.122-1.64 2.318-1.851 3.291-.104.48-.086.848.003 1.115.082.245.242.464.556.645l.002.002c.004.004.009.01.057-.004.09-.025.26-.106.507-.32.5-.428 1.084-1.183 1.72-2.186.976-1.537 1.948-3.444 2.829-5.171.261-.513.515-1.01.758-1.477.781-1.502 1.611-2.697 2.56-3.26.518-.307 1.116-.452 1.744-.303.6.143 1.09.522 1.486.979.542.624.68 1.381.616 2.097-.06.695-.311 1.412-.625 2.094-.555 1.205-1.42 2.529-2.208 3.733-.1.15-.197.3-.293.447-.755 1.162-1.195 2.086-1.351 2.804-.147.678-.027 1.075.232 1.373.08.093.133.104.192.104.093 0 .3-.042.613-.264.634-.45 1.448-1.46 2.189-3.168l1.834.796c-.815 1.878-1.816 3.258-2.866 4.003-.53.376-1.134.633-1.77.633a2.22 2.22 0 01-1.703-.793c-.778-.896-.918-1.992-.676-3.108.234-1.075.83-2.242 1.63-3.47l.292-.448c.803-1.229 1.58-2.418 2.068-3.479.272-.591.418-1.068.45-1.432.03-.342-.044-.508-.134-.611-.236-.272-.386-.331-.439-.344-.025-.006-.094-.022-.26.077-.42.25-1.033.977-1.807 2.463-.225.433-.466.906-.72 1.402-.885 1.738-1.92 3.77-2.953 5.395-.66 1.04-1.372 2.003-2.104 2.632-.698.6-1.768 1.171-2.869.537-.723-.416-1.211-1.019-1.454-1.745-.235-.704-.216-1.456-.06-2.171.307-1.417 1.195-2.91 2.295-4.179 1.001-1.154 1.675-2.225 1.94-3.077.129-.418.141-.725.097-.939a.828.828 0 00-.335-.512c-.319-.245-.763-.291-1.257.023z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgSketch
