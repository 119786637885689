import * as React from "react"
function SvgWarningsmall(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={10} cy={10} r={7.5} stroke="#F04858" />
            <path
                d="M9.45 10.502L9.1 6.995a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0zM11 13a1 1 0 11-2 0 1 1 0 012 0z"
                fill="#F04858"
            />
        </svg>
    )
}
export default SvgWarningsmall
