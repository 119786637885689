export const interfaceColors = {
    red: {
        100: "#FEEDEE",
        200: "#FCDADE",
        300: "#F9B6BC",
        500: "#F04858",
        600: "#D8414F",
        700: "#C03A46",
        800: "#902B35",
    },
    orange: {
        100: "#FFF1E9",
        200: "#FEE3D3",
        300: "#FEC6A7",
        500: "#FC7124",
        600: "#E36620",
        700: "#CA5A1D",
        800: "#974416",
    },
    yellow: {
        100: "#FFF7E8",
        200: "#FEEFD1",
        300: "#FDDEA3",
        500: "#FAAD18",
        600: "#E19C16",
        700: "#C88A13",
        800: "#96680E",
    },
    green: {
        100: "#EAF7EF",
        200: "#D5F0E0",
        300: "#ACE0C1",
        500: "#2FB264",
        600: "#2AA05A",
        700: "#268E50",
        800: "#1C6B3C",
    },
    blue: {
        100: "#ECF2FD",
        200: "#DAE5FB",
        300: "#B5CBF7",
        500: "#467EEB",
        600: "#3F71D3",
        700: "#3865BC",
        800: "#2A4C8D",
    },
    gray: {
        100: "hsl(0 0 96%)",
        500: "#A0A0A0",
    },
}
