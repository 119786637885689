import * as React from "react"
function SvgGSuiteLogo(props) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M24 12.524c0-.832-.067-1.67-.211-2.488H12.242v4.714h6.612a5.666 5.666 0 01-2.447 3.72v3.06h3.945C22.669 19.396 24 16.247 24 12.523z"
                fill="#4285F4"
            />
            <path
                d="M12.242 24.484c3.301 0 6.086-1.084 8.114-2.955l-3.944-3.059c-1.098.747-2.515 1.17-4.166 1.17-3.193 0-5.901-2.155-6.873-5.052h-4.07v3.154a12.243 12.243 0 0010.939 6.742z"
                fill="#34A853"
            />
            <path
                d="M5.369 14.588a7.333 7.333 0 010-4.687V6.748H1.302a12.252 12.252 0 000 10.994l4.067-3.154z"
                fill="#FBBC04"
            />
            <path
                d="M12.242 4.845a6.652 6.652 0 014.696 1.836l3.495-3.495A11.766 11.766 0 0012.242 0a12.24 12.24 0 00-10.94 6.747L5.37 9.901C6.336 7 9.049 4.845 12.242 4.845z"
                fill="#EA4335"
            />
        </svg>
    )
}
export default SvgGSuiteLogo
