import Img from "next/image"
import { sanityConfig } from "../../config/sanity"
import { useNextImage } from "../useNextImage"
import { css } from "@shiftx/styles"

const myCustomImageBuilder =
    ({ width, height, quality }) =>
    imageUrlBuilder => {
        /* return imageUrlBuilder.width(
        options.width || Math.min(options.originalImageDimensions.width, 1920)
    )
 */
        if (width && height) {
            return imageUrlBuilder
                .width(width)
                .height(height)
                .fit("clip")
                .auto("format")
                .dpr(2)
                .quality(quality)
        }

        return imageUrlBuilder
            .fit("clip")
            .auto("format")
            .dpr(2)
            .quality(quality)
    }

export const Image = ({
    image,
    width = null,
    height = null,
    quality = 75,
    alt = "",
    ...props
}) => {
    const imageProps = useGetImageUrl({ image, width, height, quality })
    return (
        <Img
            loader={myCustomImageBuilder}
            {...imageProps}
            alt={alt}
            sizes="(max-width: 800px) 100vw, 800px"
            css={css`
                width: 100%;
                height: auto;
            `}
            {...props}
        />
    )
}

export const useGetImageUrl = ({
    image,
    width = null,
    height = null,
    quality,
}) => {
    const imageProps = useNextImage(sanityConfig, image, {
        imageBuilder: myCustomImageBuilder({ width, height, quality }),
    })
    return imageProps
}
