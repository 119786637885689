import * as React from "react"
function SvgShiftXLogo(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 0h7.2C7.2 9.6 24 9.6 24 24h-7.2C16.8 14.4 0 14.4 0 0zM21.672 8.048C23.081 5.948 24 3.368 24 0h-7.2c0 1.556-.441 2.86-1.18 4.013l6.053 4.035zM0 24h7.2c0-1.556.441-2.86 1.18-4.013l-6.053-4.035C.92 18.052 0 20.632 0 24z"
                fill="#4040F0"
            />
        </svg>
    )
}
export default SvgShiftXLogo
