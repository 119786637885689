import { isProd } from "../isProd"

const dev = {
    segment: "I5OduffOtajNzUIVeiW8J4y4cZdPNpvA",
    iframely: "f181443c27ef209fb29f50",
}

const prod = {
    segment: "MufCiqLoRbuYE1zN0Kz8womJLzBAEBau",
    iframely: "a6ac43b027299acbf32d18",
}

export const apiKeys: typeof dev = isProd() ? prod : dev
