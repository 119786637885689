import * as React from "react"
function SvgPeople(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 10a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 2a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 13a4 4 0 00-4 4v4h2v-4a2 2 0 012-2h6a2 2 0 012 2v4h2v-4c0-.345-.044-.68-.126-1H19a1 1 0 011 1v4h2v-4a3 3 0 00-3-3h-4.354A3.985 3.985 0 0012 13H6z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgPeople
