import * as React from "react"
function SvgOrderedList(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 7H9V5h12v2zm-2 6H9v-2h10v2zM9 19h12v-2H9v2z"
                fill="currentColor"
            />
            <path
                d="M5.093 8h-1.01V5.044l-.99.266v-.703L5 4h.093v4zM6 14H3.086v-.596l1.342-1.332c.331-.356.496-.638.496-.848 0-.17-.039-.299-.117-.387-.079-.089-.192-.133-.341-.133a.432.432 0 00-.359.179.695.695 0 00-.138.441H3c0-.242.064-.465.192-.669.128-.205.306-.366.534-.482.227-.115.481-.173.763-.173.45 0 .798.098 1.04.295.245.197.368.48.368.848 0 .155-.03.307-.092.455-.061.146-.157.3-.287.463-.128.16-.335.376-.622.647l-.54.588H6V14zM3.992 17.625h.451c.358 0 .536-.165.536-.495a.41.41 0 00-.127-.312.498.498 0 00-.36-.123.545.545 0 00-.332.104.313.313 0 00-.14.26h-.955c0-.206.06-.388.182-.549a1.18 1.18 0 01.502-.374c.215-.09.451-.136.708-.136.46 0 .82.099 1.084.297.262.197.394.469.394.815a.792.792 0 01-.165.475 1.145 1.145 0 01-.473.36c.22.076.391.19.516.343.125.151.187.34.187.564a.984.984 0 01-.425.833c-.284.209-.656.313-1.118.313-.27 0-.521-.048-.754-.144a1.18 1.18 0 01-.524-.404A1.011 1.011 0 013 18.864h.961c0 .12.051.223.153.31a.56.56 0 00.377.131.591.591 0 00.403-.13c.1-.09.15-.203.15-.34 0-.196-.052-.335-.156-.417-.103-.082-.247-.123-.43-.123h-.466v-.67z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgOrderedList
