import * as React from "react"
function SvgClock(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 12a7 7 0 1114 0 7 7 0 01-14 0zm7-9a9 9 0 100 18 9 9 0 000-18zm.5 4.5h-2v5.535l3.445 2.297 1.11-1.664-2.555-1.703V7.5z"
                fill="currentColor"
            />
        </svg>
    )
}
export default SvgClock
