export const pluginConfig = ({ setHasAccepted }) => {
    return {
        current_lang: "en",
        autoclear_cookies: true, // default: false
        //page_scripts: true, // default: false

        mode: "opt-in", // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
            const analyticsEnabled = window.CC.allowedCategory("livechat")
            console.log(
                `analytics ${analyticsEnabled ? "enabled" : "disabled"}`
            )

            setHasAccepted(analyticsEnabled)
        },

        onAccept: function (cookie) {
            // ...
        },

        onChange: function (cookie, changed_preferences) {
            const findLivechat = cookie.categories.find(
                item => item === "livechat"
            )

            if (findLivechat) {
                setHasAccepted(true)
            } else {
                setHasAccepted(false)
            }

            // ...
        },

        languages: {
            en: {
                consent_modal: {
                    title: "Can we store cookies?",
                    description:
                        'This website use essential cookies to power the Live chat support. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: "Accept all",
                        role: "accept_all", // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: "Reject all",
                        role: "accept_necessary", // 'settings' or 'accept_necessary'
                    },
                },
                settings_modal: {
                    title: "Cookie Settings",
                    save_settings_btn: "Save settings",
                    accept_all_btn: "Accept all",
                    reject_all_btn: "Reject all",
                    close_btn_label: "Close",
                    cookie_table_headers: [
                        { col1: "Name" },
                        { col2: "Domain" },
                        { col3: "Expiration" },
                    ],
                    blocks: [
                        {
                            title: "Cookie usage 📢",
                            description:
                                'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a>.',
                        },
                        {
                            title: "Strictly necessary cookies",
                            description:
                                "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                            toggle: {
                                value: "necessary",
                                enabled: true,
                                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                        },
                        {
                            title: "Live chat cookies",
                            description:
                                "These cookies allow the live chat to pop up when you need our help.",
                            toggle: {
                                value: "livechat", // your cookie category
                                enabled: true,
                                readonly: false,
                            },
                            cookie_table: [
                                // list of all expected cookies
                                {
                                    col1: "^intercom", // match all cookies starting with "_ga"
                                    col2: "intercom.com",
                                    col3: "9 months",
                                    is_regex: true,
                                },
                                {
                                    col1: "intercom-session-[app_id]",
                                    col2: "intercom.com",
                                    col3: "1 week",
                                },
                                {
                                    col1: "intercom-id-[app_id]",
                                    col2: "intercom.com",
                                    col3: "9 months",
                                },
                            ],
                        },

                        {
                            title: "More information",
                            description:
                                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                        },
                    ],
                },
            },
        },
    }
}
