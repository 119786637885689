import { interfaceColors } from "@shiftx/data"
import { css, getColor, Global, lightTheme } from "@shiftx/styles"
import React from "react"
import { lessThan } from "../utils/breakpoints"
import { useRouter } from "next/router"

export const GlobalStyle = () => {
    const router = useRouter()

    const noBackgroundRoutes = [
        "/use-case/process-mapping",
        "/use-case/customer-journey",
        "/use-case/procedures",
    ]

    return (
        <Global
            styles={theme => css`
                body {
                    /*  background: linear-gradient(
                        180deg,
                        rgba(236, 242, 253, 1) 0%,
                        rgba(210, 97, 255, 0) 500px
                    );
 */

                    background: #fff;
                    ${router.pathname === "/ai" &&
                    css`
                        background: linear-gradient(
                                180deg,
                                #7357ff11 0px,
                                #7357ff00 640px
                            )
                            #171717;
                        color: var(--fg-high);
                    `};

                    ${noBackgroundRoutes.includes(router.asPath) &&
                    css`
                        background: none;
                    `};
                }

                video {
                    -webkit-mask-image: -webkit-radial-gradient(white, black);
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                }

                div,
                section {
                    box-sizing: border-box;
                }

                [id] {
                    scroll-margin-top: 100px;
                }

                html {
                    scroll-behavior: smooth;
                }

                body {
                    margin: 0px;
                    font-family:
                        Albert Sans,
                        sans-serif;
                    -webkit-tap-highlight-color: transparent;
                    font-size: 16px;
                    line-height: 28px;
                    color: var(--fg-high);
                }

                strong {
                    font-weight: 600;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
                svg {
                    display: block;
                }

                h1,
                h2 {
                    margin: 0;
                }

                div {
                    box-sizing: border-box;
                }

                div:focus {
                    outline: none;
                }

                .noscroll {
                    overflow-y: hidden;
                }

                :root {
                    --brand-base: ${getColor(theme, "brandBase")};
                    --brand-low: ${getColor(theme, "brandLow")};
                    --brand-high: ${getColor(theme, "brandHigh")};
                    --on-brand: ${getColor(theme, "onBrand")};
                    --bg-primary: ${getColor(theme, "bgPrimary")};
                    --bg-secondary: ${getColor(theme, "bgSecondary")};
                    --bg-elevated: ${getColor(theme, "bgElevated")};
                    --fg-100: ${getColor(theme, "fg100")};
                    --fg-100-solid: ${getColor(theme, "fg100Solid")};
                    --fg-200: ${getColor(theme, "fg200")};
                    --fg-200-solid: ${getColor(theme, "fg200Solid")};
                    --fg-300: ${getColor(theme, "fg300")};
                    --fg-300-solid: ${getColor(theme, "fg300Solid")};
                    --fg-400: ${getColor(theme, "fg400")};
                    --fg-400-solid: ${getColor(theme, "fg400Solid")};
                    --fg-500: ${getColor(theme, "fg500")};
                    --fg-500-solid: ${getColor(theme, "fg500Solid")};
                    --fg-low: ${getColor(theme, "fgLow")};
                    --fg-low-solid: ${getColor(theme, "fgLowSolid")};
                    --fg-medium: ${getColor(theme, "fgMedium")};
                    --fg-medium-solid: ${getColor(theme, "fgMediumSolid")};
                    --fg-high: ${getColor(theme, "fgHigh")};
                    --fg-high-solid: ${getColor(theme, "fgHighSolid")};
                    --fg-color: ${getColor(theme, "fgColor")};

                    --red-100: ${interfaceColors.red[100]};
                    --red-200: ${interfaceColors.red[200]};
                    --red-300: ${interfaceColors.red[300]};
                    --red-500: ${interfaceColors.red[500]};
                    --red-600: ${interfaceColors.red[600]};
                    --red-700: ${interfaceColors.red[700]};
                    --red-800: ${interfaceColors.red[800]};
                    --blue-100: ${interfaceColors.blue[100]};
                    --blue-200: ${interfaceColors.blue[200]};
                    --blue-300: ${interfaceColors.blue[300]};
                    --blue-500: ${interfaceColors.blue[500]};
                    --blue-600: ${interfaceColors.blue[600]};
                    --blue-700: ${interfaceColors.blue[700]};
                    --blue-800: ${interfaceColors.blue[800]};
                    --green-100: ${interfaceColors.green[100]};
                    --green-200: ${interfaceColors.green[200]};
                    --green-300: ${interfaceColors.green[300]};
                    --green-500: ${interfaceColors.green[500]};
                    --green-600: ${interfaceColors.green[600]};
                    --green-700: ${interfaceColors.green[700]};
                    --green-800: ${interfaceColors.green[800]};
                    --yellow-100: ${interfaceColors.yellow[100]};
                    --yellow-200: ${interfaceColors.yellow[200]};
                    --yellow-300: ${interfaceColors.yellow[300]};
                    --yellow-500: ${interfaceColors.yellow[500]};
                    --yellow-600: ${interfaceColors.yellow[600]};
                    --yellow-700: ${interfaceColors.yellow[700]};
                    --yellow-800: ${interfaceColors.yellow[800]};
                    --orange-100: ${interfaceColors.orange[100]};
                    --orange-200: ${interfaceColors.orange[200]};
                    --orange-300: ${interfaceColors.orange[300]};
                    --orange-500: ${interfaceColors.orange[500]};
                    --orange-600: ${interfaceColors.orange[600]};
                    --orange-700: ${interfaceColors.orange[700]};
                    --orange-800: ${interfaceColors.orange[800]};
                    --section-margin: 160px;
                    --section-margin-small: 120px;
                    --section-margin-inside: 80px;
                    --grid-gap: 4rem;

                    ${lessThan(1)`
                    --section-margin: 80px;
                    --section-margin-small: 60px;
                    --section-margin-inside: 40px;
                    --grid-gap: 2rem;
                `}
                }
            `}
        />
    )
}
